import React, {useState} from 'react';
import {MobileMenu} from "./MobileMenu";
import { Link } from 'react-router-dom';
import Logo from './img/logo-aok.svg';
export const Header = ({toggleContrast, setIsSubmitted}) => {
    const [popupEnabled, setpopupEnabled] = useState(false);
    const togglePopup = () => {
        setpopupEnabled(!popupEnabled);
    };
    return (
        <div id="desktop-header" className="navbar-fixed-top sticky">
            <div id="mobile-header" className="visible-xs visible-sm navbar-fixed-top">
                <div className="flex">
                    <div className="flex-content">
                        <a className="page-logo" id="butt10" href="/">
                            <img className="navbar-brand-logo-normal" src={Logo} alt="Logo AOK" />
                        </a>
                    </div>
                    <div className="flex-content">
                        <div className="program-name">
                            <span className="line-2">AOK Pflegen zu Hause</span>
                        </div>
                    </div>
                    <div className="flex-content">
                        <MobileMenu  setIsSubmitted={setIsSubmitted} />
                    </div>
                </div>
            </div>
            <div id="header-portal" className="hidden-xs hidden-sm">
                <div className="container">
                    <div className="table">
                        <div className="table-cell-50">
                            <a href="https://www.aok.de" title="zu aok.de" target="_blank" rel="noopener noreferrer" className="aok-link"> zu aok.de</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="header-logo" className="hidden-xs hidden-sm ">
                <div className="container">
                    <div className="flex">
                        <div className="flex-content">
                            <a title="AOK NDS Prämienshop" id="butt11" href="#top">
                                <img className="navbar-brand-logo-normal" alt="logo" src={Logo}/>
                            </a>
                        </div>
                        <div className="flex-content">
                            <div className="program-name">
                                <span className="line-2">AOK Pflegen zu Hause</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="main-navigation" className="bg-gray">
                    <div className="container">
                        <div id="menuitems" className="navbar navbar-default menuitems">
                            <Link to="/"  onClick={() => {setIsSubmitted(false);  window.scrollTo(0, 0)
                            }} className="btns active">Registrieren
                                <div className="divider"></div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-content accessibility-content">
                <button className="accessibility__trigger" onClick={togglePopup}><i className="material-icons">accessibility</i></button>
                <div className={popupEnabled ? 'accessibility-popup' : 'accessibility-popup-disabled'}>
                    <button className="btn-close" onClick={togglePopup}>X</button>
                    <h1>Barrierefreiheit</h1>
                    <h2>Kontrast</h2>
                    <input onChange={toggleContrast} type="checkbox"  id="accessibility"/>
                    <label style={{marginLeft: 5, fontSize: 22}} htmlFor="accessibility">Kontrastmodus aktiviert</label>
                    <h2>Schriftgröße</h2>
                    <p style={{fontSize: 22}}>
                        Benutzen Sie die browserinterne Funktion zu Anpassung der
                        Schriftgrößen: <code>Strg+Plus</code>, <code>Strg+Minus</code> & <code>Strg+0</code> (Linux/Windows)
                        bzw. <code>Cmd+Plus</code> und <code>Cmd+Minus</code> (Mac).
                    </p>
                </div>
            </div>
        </div>

    )
};