import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = (props) => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="footer-headline">aok.de</div>
                <ul className="footer-menu">
                    <li>
                        <span className="headline">Rechtliche Informationen</span>
                        <ul>
                            <li>
                                <Link to="/bedingungen" onClick={() => {
                                    window.scrollTo(0, 0)
                                }}>
                                    Allgemeine Nutzungs- und Geschäftsbedingungen
                                </Link>
                            </li>
                            <li >
                                <Link to="/datenschutz" onClick={() => {
                                    window.scrollTo(0, 0)
                                }} >
                                     Datenschutzerklärung
                                </Link>
                            </li>
                            <li >
                                <Link to="/cookies" onClick={() => {
                                    window.scrollTo(0, 0)
                                }} >
                                     Cookies
                                </Link>
                            </li>
                            <li >
                                <Link to="/impressum" title="Impressum" onClick={() => {
                                    window.scrollTo(0, 0)
                                }}>Impressum</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="clients">
                        <span className="headline">Ihre AOK</span>
                        <ul>
                            <li>
                                <a href="https://aok.de/pk/bayern/" className="js-footer-localisation" data-switch="12"
                                   data-event="localization" data-evalue="AOK Bayern">
                                    AOK Bayern</a>
                            </li>
                            <li>
                                <a href="https://aok.de/pk/niedersachsen/" className="js-footer-localisation" data-switch="7"
                                   data-event="localization" data-evalue="AOK Niedersachsen">
                                    AOK Niedersachsen</a>
                            </li>
                            <li>
                                <a href="https://aok.de/pk/nordost/" className="js-footer-localisation" data-switch="10"
                                   data-event="localization" data-evalue="AOK Nordost">
                                    AOK Nordost</a>
                            </li>
                            <li>
                                <a href="https://aok.de/nw/" className="js-footer-localisation" data-switch="10"
                                   data-event="localization" data-evalue="AOK Nordwest">
                                    AOK Nordwest</a>
                            </li>
                            <li>
                                <a href="https://aok.de/rh/" className="js-footer-localisation" data-switch="10"
                                   data-event="localization" data-evalue="AOK rheinland/hamburg">
                                    AOK Rheinland/Hamburg</a>
                            </li>
                            <li>
                                <a href="https://aok.de/rps/" className="js-footer-localisation" data-switch="10"
                                   data-event="localization" data-evalue="AOK rheinland-pfalz/saarland">
                                    AOK Rheinland-Pfalz/Saarland</a>
                            </li>
                            <li>
                                <a href="https://aok.de/sachsen-anhalt/" className="js-footer-localisation" data-switch="10"
                                   data-event="localization" data-evalue="AOK Sachsen-Anhalt">
                                    AOK Sachsen-Anhalt</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </footer>
    );
}
