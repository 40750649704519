import React from 'react';

export const Cookies = () => (
    <article className="container detail-content is-centered with-in-site-navigation">

        <h1>Cookies</h1>


        <div className="rich-text">
            <p>Im Rahmen unserer Internetseiten verwenden wir überdies Cookies. Bei Cookies handelt es sich um kleine Dateien, die auf zu Ihrem Browser bzw. auf Ihrer Festplatte gespeichert werden und über die dem Verwender bestimmte Informationen zufließen können.</p>
            <ul>
                <li>Durch unsere Cookies fließen uns zum einen die Login-Daten zur Authentifizierung Ihrer Person als Nutzer zu.</li>
                <li>Zum anderen werden Cookies unseres Web Content Management Systems (Coremedia) gesetzt. Durch diese Cookies fließen uns Informationen zum Browsertyp und dessen Version, dem URL-Clickstream (Reihenfolge der von Ihnen besuchten Seiten unseres Internetangebots), dem Datum und der Zeit des Besuchs in unserem Portal sowie die Cookie-Nummer zu, nicht jedoch personenbezogene Daten oder auch nur IP-Adressen unserer Nutzer. Diese Cookies dienen lediglich statistischen Zwecken, indem wir zum Beispiel erfahren, welche Inhalte unserer Website am meisten abgerufen werden. Diese Informationen dienen uns dazu, Rubriken wie „meist gelesen“ zu schaffen und auf diesem Weg die Website für unsere Nutzer komfortabler zu gestalten. Durch die statistischen Erkenntnisse zu Browsertyp und Betriebssystem können wir zudem unser Webdesign optimieren.</li>
                <li>Weitere Cookies bzw. Zählpixel werden zur Ermittlung statistischer Informationen wie der Nutzung von Zugangswegen zu unserem Portal (Anzeige, Suchmaschine etc.) eingesetzt, hierbei werden weder personenbezogene Daten noch IP-Adressen erhoben. Aktuell besteht eine Kooperation mit den Anbietern:sociomantic labs GmbH, Paul-Lincke-Ufer 39/40, 10999 BerlinGoogle Germany GmbH, ABC-Straße 19, 20354 Hamburg</li>
            </ul>
            <p>Falls Sie die Verwendung von Cookies unterbinden wollen, besteht dazu bei Ihrem Browser die Möglichkeit, die Annahme und Speicherung neuer Cookies zu verhindern.</p>

        </div>


    </article>
)
