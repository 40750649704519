import React from 'react';

export const Datenschutz = () => (
    <article className="container detail-content is-centered with-in-site-navigation">

        <h1>Datenschutzerklärung</h1>

        <div className="rich-text">
        <p>Dies ist die Datenschutzerklärung („Erklärung“) für die Webseite pflegenzuhause.de, die von der Springer Medizin Verlag GmbH, Heidelberger Platz 3, 14197 Berlin, Email:&nbsp;kundenservice@pflegenzuhause.de&nbsp;(wir,&nbsp;uns&nbsp;und&nbsp;unser) betrieben und bereitgestellt wird.</p>
        <p>Wir verarbeiten die über diese Webseite erhobenen personenbezogenen Daten ausschließlich wie in dieser Erklärung niedergelegt. Weiter unten erfahren Sie, wie wir Ihre personenbezogenen Daten verarbeiten, zu welchen Zwecken sie verarbeitet werden, mit wem sie geteilt werden und welche Kontroll- und Informationsrechte Ihnen ggf. zustehen.</p>
        <h2>I. Zusammenfassung unserer Verarbeitungsaktivitäten</h2>
        <p>In der nachfolgenden Zusammenfassung werden Ihnen die Verarbeitungsaktivitäten auf unserer Webseite kurz dargestellt. Nähere Informationen dazu finden Sie in den ausgewiesenen Abschnitten weiter unten.</p>
        <p>Falls Sie unsere Webseite zu informatorischen Zwecken besuchen, ohne dabei ein Benutzerkonto einzurichten, werden nur begrenzte personenbezogene Daten verarbeitet, um Ihnen die Webseite anzuzeigen (siehe unter III).</p>
        <p>Falls Sie sich für eine unserer Leistungen (z. B. zugangsbeschränkte Informationen, Abonnements oder Webshop) registrieren oder unseren Newsletter abonnieren, werden in diesem Rahmen weitere personenbezogene Daten verarbeitet (siehe unter IV, V und VI).</p>
        <p>Des Weiteren werden Ihre personenbezogenen Daten für interessante Werbung für unsere Leistungen und Produkte (siehe unter VIII) sowie für die statistische Analyse zur Verbesserung unserer Webseite (siehe unter IX) verwendet. Zusätzlich steigern wir Ihr Nutzungserlebnis durch Inhalte Dritter (siehe unter X).</p>
        <p>Ggf. werden Ihre personenbezogenen Daten Dritten offengelegt (siehe unter XI), die sich in außerhalb des Landes Ihres Wohnorts befinden, in denen möglicherweise andere Datenschutzstandards gelten (siehe unter XII).</p>
        <p>Wir haben angemessene Sicherheitsmaßnahmen ergriffen, um Ihre personenbezogenen Daten zu schützen (siehe XIII) und speichern diese nur solange wie nötig (siehe unter XIV).</p>
        <p>Je nach den Gegebenheiten des konkreten Falls, stehen Ihnen ggf. bestimmte Rechte in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten zu (siehe unter XV).</p>
        <h2>II. Begriffsbestimmungen</h2>
        <ul>
            <li><strong>Personenbezogene Daten</strong>&nbsp;sind alle Informationen über eine lebende Person, die direkt oder indirekt identifiziert werden kann, insbesondere durch die Zuordnung zu einer Kennung, wie etwa einem Namen, einer Kennnummer, Standortdaten oder einer Online-Kennung.</li>
            <li><strong>Verarbeitung</strong>&nbsp;ist jeder Vorgang im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, die Offenlegung oder jede sonstige Art der Nutzung.</li>
        </ul>
        <h2>III. Nutzung der Webseite zu informatorischen Zwecken</h2>
        <p>Falls Sie unsere Webseite zu informativen Zwecken besuchen, also ohne sich für eine der von uns angebotenen, unter IV aufgeführten Leistungen anzumelden und ohne uns personenbezogene Daten auf andere Weise bereitzustellen, erheben wir ggf. automatisch ergänzende Informationen über Sie, die nur in begrenzten Fällen personenbezogene Daten enthalten und von unserem Server automatisch erfasst werden, wie etwa:</p>
        <p>Ihr Gerätetyp, -name und Ihre Bildschirmauflösung;</p>
        <p>Angaben zu Ihrer Browserversion;</p>
        <p>Angaben zu Ihrem Betriebssystem, einschließlich zu Ihren Spracheinstellungen;</p>
        <p>Datum und Zeitpunkt Ihrer Anfragen;</p>
        <p>Inhalte Ihrer Anfragen;</p>
        <p>Wir nutzen diese Informationen lediglich dazu, Ihnen unsere Leistungen effektiv anbieten zu können (z. B. durch die Anpassung unserer Webseite an die Bedürfnisse Ihres Geräts oder dadurch, Ihnen zu erlauben, sich auf unserer Webseite einzuloggen) und umfassende demographische Daten zur Erstellung einer anonymisierten Statistik über die Nutzung unserer Webseite zu erstellen.</p>
        <p>Wir benötigen die automatisch erhobenen personenbezogenen Daten für die Bereitstellung unserer Webseite, Art. 6 Absatz 1 Satz 1 lit. b DSGVO, sowie für unser berechtigtes Interesse, die Stabilität und Sicherheit der Webseite zu gewährleisten, Art. 6 Absatz 1 Satz 1 lit. f DSGVO.</p>
        <p>Automatisch erhobene personenbezogene Daten werden 1 Monat gespeichert und anschließend ordnungsgemäß gelöscht.</p>
        <p>Im Fall des Auftretens eines Fehlers oder technischen Problems auf der Website werden bestimmte Daten in so genannten Logfiles gespeichert. Ein Logfile besteht aus dem Datum und der Uhrzeit des Auftretens des Fehlers. &nbsp;Wir weisen in diesem Zusammenhang vorsorglich darauf hin, dass unsere Logfiles keine IP-Adressen beinhalten. Unsere Logfiles sind also vollständig anonym.</p>
        <h2>IV. Anmeldung zu unseren Leistungen</h2>
        <p>Unsere Webseite bietet zugangsbeschränkte Informationen, Abonnements, eLearnings und einen Webshop. Um die o. g. Leistungen nutzen zu können, müssen Sie ein Benutzerkonto anlegen.</p>
        <p>Beim Anlegen eines Benutzerkontos und seiner anschließenden Nutzung verwenden wir den Authentifizierungs-Dienst der Springer Nature GmbH, Heidelberger Platz 3, 14197 Berlin einen separaten Dienst, der als zentrale Instanz für den Login auf pflegenzuhause.de ebenso wie auch allen weiteren teilnehmenden Portalen der Fachverlage der Fachverlagsgruppe Springer Nature dient. Die für den Authentifizierungs-Dienst geltenden Allgemeinen Nutzungsbedingungen finden Sie&nbsp;hier&nbsp;sowie die Datenschutzerklärung für den Authentifizierungs-Dienst&nbsp;hier&nbsp;unten.</p>
        <p>Wenn Sie sich nach Ihrer Registrierung über den Authentifizierungs-Dienst mit Ihren Zugangsdaten bei pflegenzuhause.de anmelden, senden wir die Daten, die Sie im Anmeldeformular eingegeben haben (i.d.R. Ihre Email-Adresse und Ihr Passwort), sowie eine Service-URL an den Server des Authentifizierungs-Dienstes. Dieser verifiziert mit diesen Daten die Berechtigung Ihrer Anmeldung. Ist die Anmeldung danach berechtigt, erhalten wir die Ihnen vom Authentifizierungs-Dienst zugewiesene User-ID übermittelt. Mit diesen Daten können Ihnen auf pflegenzuhause.de zum Beispiel die ggf. bereits personalisierten Einstellungen angezeigt werden. Zudem haben wir die Möglichkeit, über einen entsprechenden Webservice auch die weiteren für die Leistungserbringung relevanten Daten bei dem Authentifizierungs-Dienst anzufragen. Auf diesem Weg können wir Ihnen dann Ihr Produkt zugänglich machen sowie alle anderen Leistungen erbringen, die Sie bei uns gebucht haben.</p>
        <p>Für die Registrierung verarbeiten wir mindestens</p>
        <p>Angaben (wie etwa Ihr Name und Benutzername und Ihre E-Mail-Adresse), die bei der Anmeldung gemacht werden;</p>
        <p>Informationen beim Einloggen in das Benutzerkonto (z. B. Login- und Passwortangaben);</p>
        <p>Abhängig von der Art der von Ihnen ausgewählten Leistungen können weitere Angaben erforderlich sein, z.B. die Angabe des Geburtsdatums für die Teilnahme an Online-Fortbildungen (eLearning).</p>
        <p>Die für die Erbringung der Leistung erforderlichen Informationen sind entsprechend gekennzeichnet. Alle weiteren Angaben erfolgen freiwillig.</p>
        <p>Die Rechtsgrundlage hierfür bildet Art. 6 Absatz 1 Satz 1 lit. b DSGVO.</p>
        <p>Die Nutzung Ihrer personenbezogenen Daten zum Zwecke der verhaltensbasierten Werbung und des Profilings erfolgt aufgrund des berechtigten Interesses, Ihr Nutzungserlebnis zu steigern, Art. 6 Absatz 1 Satz 1 lit. f DSGVO.]</p>
        <p>Wir verarbeiten die von Ihnen im Rahmen der Anmeldung bereitgestellten personenbezogenen Daten und Kontaktdaten, um Sie direkt über unsere weiteren Produkte und Leistungen zu informieren. Die Nutzung Ihrer personenbezogenen Daten für das Bewerben von verwandten Produkten und Leistungen im Wege der Direktwerbung stellt für uns als Anbieter dieser Webseite ein berechtigtes Interesse dar, Art. 6 Absatz 1 Satz 1 lit. f DSGVO.</p>
        <p>Sie können der Verarbeitung Ihrer personenbezogenen Daten zum Zwecke der Direktwerbung jederzeit widersprechen. Wir werden dann von einer weiteren Verarbeitung zu solchen Zwecken absehen. Ihren Widerspruch können Sie uns wie folgt zukommen lassen:<br />support@aok.pflegenzuhause.de.</p>
        <p>Sofern keine Ausnahmen innerhalb der weiter unten genannten Leistungen greifen (z.B. wenn Zertifikate für Fortbildungen erworben wurden), werden Ihre personenbezogenen Daten so lange gespeichert, wie Sie Ihr Benutzerkonto verwenden. Nach Löschung Ihres Kontos werden Ihre personenbezogenen Daten innerhalb von 1 Monat gelöscht. Gesetzliche Aufbewahrungspflichten oder das Erfordernis Ihrer personenbezogenen Daten für rechtliche Schritte wegen Fehlverhaltens bei der Leistungsnutzung oder bei Zahlungsproblemen kann dazu führen, dass wir Ihre personenbezogenen Daten länger speichern. In einem solchen Fall werden wir Sie dementsprechend informieren.</p>
        <h2>V. Informationen über spezielle Nutzungsmöglichkeiten, die eine Anmeldung erfordern</h2>
        <p>Um eine der nachfolgend beschriebenen Funktionen nutzen zu können, müssen Sie, wie unter IV beschrieben, zunächst ein Benutzerkonto anlegen.</p>
        <p><strong>1. Remember Me</strong></p>
        <p>Wenn Sie die sog. „remember me-Funktion“ bei Ihrem Login mittels des SSO-Dienstes aktiviert haben, setzen wir einen Cookie, durch den uns die Login-Daten (siehe IV) zur Authentifizierung Ihrer Person als Nutzer zufließen und wir Sie dadurch bei Ihrem nächsten Login automatisch anmelden können.</p>
        <p>Zum anderen werden von uns temporäre Cookies gesetzt. Durch diese Cookies erheben wir Informationen zu der von Ihnen auf pflegenzuhause.de zuletzt besuchten Seite (URL). Personenbezogene Daten oder die IP-Adressen unserer Nutzer erheben wir hierüber nicht. Diese Cookies dienen ausschließlich der korrekten Anzeige von Seiten auf Basis der von Ihnen getroffenen Auswahl und werden gelöscht, sobald Sie den Browser schließen oder sich ausloggen.</p>
        <p><strong>2. Test-Abonnements und kostenpflichtige Verträge</strong></p>
        <p>Als registrierter Nutzer haben Sie die Möglichkeit, den Zugang zu bestimmten Bereichen&nbsp;kostenpflichtig zu erwerben. Zudem haben Sie unter Umständen die Möglichkeit, für einen begrenzten Zeitraum, kostenlos auf Inhalte zuzugreifen. Die Bestellung erfolgt direkt online. Auf dem Bestellformular (auch im Fall des kostenlosen Testzugangs) erfragen wir die folgenden Daten, um uns die Zuordnung des Formulars zu ermöglichen und die Bestellung durchzuführen:</p>
        <p>Ihren Vor- und Nachnamen, Geschlecht, Titel,</p>
        <p>Ihre Postanschrift sowie ggf. eine separate Zustelladresse, sofern das gewählte Produkt Druckstücke enthält,</p>
        <p>Ihre Fachrichtung und Schwerpunkt der Tätigkeit,</p>
        <p>Sofern Sie als Geschäftskunde bestellen, zusätzlich Firmenname und Umsatzsteuer-Ident-Nr.</p>
        <p>Wenn Sie als Kunde eines Print-Produkts auf online abrufbare Inhalte Ihres Print-Produkts zugreifen, erheben und verarbeiten wir zusätzlich folgende Daten:&nbsp;</p>
        <p>Kundennummer Ihres Print-Produkts.&nbsp;</p>
        <p><strong>3. eLearning</strong></p>
        <p>Als registrierter Nutzer haben Sie die Möglichkeit an Online-Fortbildungen (eLearning) teilzunehmen. Vor der ersten Teilnahme werden ggf. zusätzliche Nutzerdaten erhoben, um Ihre Teilnahmeberechtigung zu verifizieren und Ihnen ein Zertifikat ausstellen zu können:</p>
        <p>Vollständige Anrede (inkl. akademischer Titel)</p>
        <p>Geburtsdatum</p>
        <p>Postanschrift</p>
        <p>Sofern es sich um ein von einer Fachgesellschaft unterstütztes eLearning handelt, wird zusätzlich die Gültigkeit Ihrer Mitgliedschaft überprüft, siehe unten unter 4.</p>
        <p><strong>4. Registrierung aus IP-Range</strong></p>
        <p>Wenn Sie sich im Rahmen einer Kooperationsvereinbarung für Unternehmen registrieren, wird bei der Registrierung anhand der an der letzten Stelle gekürzten IP-Adresse des von Ihnen genutzten Endgerätes geprüft, ob Sie sich innerhalb der von Ihrer Gesellschaft/Ihrem Unternehmen angegebenen IP-Range befinden und es wird für die Laufzeit des Vertrages ein Cookie auf Ihrem Endgerät gesetzt. Dazu erheben wir bei Ihrem Login die IP-Adresse von der aus Sie auf pflegenzuhause.de zugreifen und prüfen, ob diese sich innerhalb der zugangsberechtigten IP-Range befindet. Die IP-Adresse wird jedoch nicht in voller Länge, sondern gekürzt um die letzte Stelle gespeichert. Dies ist erforderlich, um Ihnen den Zugriff auf die kooperationsspezifischen Angebote und Informationen zu ermöglichen.</p>
        <p><strong>5. Webshop</strong></p>
        <p>Zur Durchführung eines Kaufprozesses werden Ihre personenbezogenen Daten an den Betreiber des Webshops, die Springer Customer Service Center GmbH übermittelt, siehe XI zur Weitergabe personenbezogener Daten an Dritte. Diese Verarbeitung beruht auf Art. 6 Absatz 1 Satz 1 lit. b und auf unserem berechtigten Interesse, den Kaufprozess innerhalb der Springer Nature Verlagsgruppe zentralisiert abzuwickeln, Art. 6 Absatz 1 Satz 1 lit. f.</p>
        <p>Sowohl wir als auch die Springer Customer Service Center GmbH sind gesetzlich dazu verpflichtet, mit Transaktionen im Zusammenhang stehende Finanzdaten (einschließlich Adress-, Zahlungs- und Bestellinformationen) zehn Jahre lang zu speichern. Nach 2 Jahren werden wir die Verarbeitung Ihrer personenbezogenen Daten jedoch zwecks Einhaltung der gesetzlichen Vorgaben einschränken und die personenbezogenen Daten dann nicht mehr weiterverarbeiten. Die diesbezügliche Speicherung Ihrer personenbezogenen Daten beruht auf Art. 6 Absatz 1 Satz 1 lit. c DSGVO.</p>
        <h2>VI. Automatisierte Entscheidungsfindung</h2>
        <p>Wir verarbeiten Ihre personenbezogenen Daten nicht für automatisierte Entscheidungsfindungen, die Ihnen gegenüber rechtliche Wirkung entfalten oder Sie in ähnlicher Weise &nbsp;erheblich beeinträchtigen.</p>
        <h2>VII. Online-Werbung</h2>
        <p>Diese Webseite nutzt Online-Werbedienste, um Ihnen auf Ihre Interessen zugeschnittene Werbung zu präsentieren. Die jeweilige Werbung ist mit dem Namen des Dienstleisters gekennzeichnet, z. B. „Google-Ad“.</p>
        <p>Zudem helfen uns lizensierte Komponenten dabei, unsere Webseite zu verbessern und Ihren Besuch auf unserer Webseite für Sie interessanter zu gestalten. Dazu erheben wir statistische Informationen über Sie, die von den Dienstleistern verarbeitet werden. Diese Verarbeitung beruht auf Art. 6 Absatz 1 Satz 1 lit. f DSGVO und stellt unser berechtigtes Interesse an der Steigerung Ihres Nutzungserlebnisses und der Bewerbung unserer Produkte und Leistungen dar.&nbsp;</p>
        <p><strong>1. Cookies, Web-Beacons</strong></p>
        <p>Der Dienstleister wird automatisch über Ihren Besuch auf unserer Webseite informiert. Dazu kommt ein Web-Beacon zum Einsatz, der einen Cookie auf Ihrem Computer platziert.</p>
        <p>Beachten Sie bitte, dass wir weder Einfluss auf den Umfang der vom jeweiligen Plug-in-Anbieter erhobenen personenbezogenen Daten haben, noch wissen, zu welchem Zweck sie verarbeitet oder wie lange sie gespeichert werden. Abhängig vom Dienstleister werden Ihre personenbezogenen Daten in Drittstaaten angeben, z. B. die Vereinigten Staaten übermittelt und dort verarbeitet. Weitere Informationen zu den potenziellen Risiken einer grenzüberschreitenden Datenübermittlung finden Sie unter XII.</p>
        <p>Es kann sein, dass der Dienstleister Ihre personenbezogenen Daten an Geschäftspartner, Dritte oder Behörden weitergibt.</p>
        <p>Das Setzen eines solchen Cookies können Sie wie folgt verhindern:</p>
        <p>(i) Durch eine entsprechende Einstellung in Ihrem Browser, die das Setzen von Cookies Dritter blockiert,</p>
        <p>(ii) durch Deaktivieren der interessenbezogenen Werbung des Dienstleisters unter dem Link, der in den untenstehenden Angaben zu den Dienstleistern genannt wird,</p>
        <p>(iii)&nbsp;oder durch allgemeines Deaktivieren von Cookies z.B. unter http://www.google.com/settings/ads/plugin.</p>
        <p>Weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten durch unseren Dienstleister finden Sie in dessen Datenschutzrichtlinie. Darüber hinaus erhalten Sie weitere Informationen über Ihre Rechte und Einstellungen zum Datenschutz. Die Datenschutzerklärung des Anbieters wird Ihnen durch Anklicken des Links in den Detailangaben zum Dienstleister angezeigt.</p>
        <p><strong>2. Facebook Custom Audience</strong></p>
        <p>Auf der Website setzen wir den Dienst „Website Custom Audiences“ des sozialen Netzwerkes Facebook ein. Dieser Dienst wird von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland betrieben. Mehr Informationen zu „Website Custom Audiences“ finden Sie unter folgendem Link:&nbsp;https://www.facebook.com/business/help/449542958510885/&nbsp;</p>
        <p>Durch diesen Dienst erzeugt Facebook nach eigenen Angaben eine nicht-reversible und nicht-personenbezogene Prüfsumme (Hash-Wert) aus Ihren Nutzungsdaten, die Facebook zu Analyse- und Marketingzwecken nutzen kann. Für das Produkt „Website Custom Audiences“ wird auf der Website durch Facebook ein Cookie, Web Beacon, Pixel oder eine ähnliche Technologie angesprochen und eventuell auf Ihrem Endgerät abgelegt. Da der Dienst und über diesen Dienst stattfindende Datenverarbeitung allein im Verantwortungsbereich von Facebook liegen, haben wir keinerlei Einfluss auf eine mögliche Verarbeitung personenbezogener Daten. Weitere Informationen über Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzrichtlinien von Facebook, welche u.a. auf&nbsp;https://www.facebook.com/ads/website_custom_audiences/&nbsp;und&nbsp;https://www.facebook.com/privacy/explanation&nbsp;zu finden sind.</p>
        <p>Wenn Sie der Nutzung von Facebook Website Custom Audiences widersprechen möchten, können Sie diesen Widerspruch (Opt-out) unter folgendem Link:&nbsp;https://www.facebook.com/ads/website_custom_audiences/&nbsp;ausüben.</p>
        <p><strong>3. Bing Ads</strong></p>
        <p>Auf der Website setzen wir Technologien von Bing Ads (bingads.microsoft.com) ein, welche von der Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA („Microsoft“) bereitgestellt und betrieben werden. Dabei wird von Microsoft ein Cookie auf Ihrem Endgerät gesetzt, sofern Sie über eine Microsoft Bing Anzeige auf unsere Website gelangt sind. Microsoft und wir können auf diese Weise erkennen, dass jemand auf eine Anzeige geklickt hat, zu unserer Website weitergeleitet wurde und eine vorher bestimmte Zielseite („Conversion Seite“) erreicht hat. Wir erfahren dabei nur die Gesamtzahl der Nutzer, die auf eine Bing Anzeige geklickt haben und dann zur Conversion Seite weitergeleitet wurden. Microsoft erhebt, verarbeitet und nutzt über das Cookie Informationen, aus denen unter Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Diese Nutzungsprofile dienen der Analyse des Besucherverhaltens und werden zur Ausspielung von Werbeanzeigen verwendet. Es werden keine persönlichen Informationen zur Identität des Nutzers verarbeitet.</p>
        <p>Wenn Sie nicht möchten, dass Informationen zu Ihrem Verhalten von Microsoft wie oben erläutert verwendet werden, können Sie das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten sowie die Verarbeitung dieser Daten durch Microsoft verhindern, indem Sie unter dem folgenden Link&nbsp;http://choice.microsoft.com/de-DE/opt-out&nbsp;Ihren Widerspruch erklären. Weitere Informationen zum Datenschutz und zu den eingesetzten Cookies bei Microsoft und Bing Ads finden Sie auf der Website von Microsoft&nbsp;https://privacy.microsoft.com/de-de/privacystatement.</p>
        <p><strong>4. Google AdWords</strong></p>
        <p>Auf der Website nutzen wir ferner das sog. Google Conversion Tracking, einen Analysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Dabei wird von Google AdWords ein Cookie auf Ihrem Endgerät gesetzt („Conversion Cookie“), sofern Sie über eine Google-Anzeige auf unsere Webseite gelangt sind. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der personenbezogenen Identifizierung von Nutzern. Besuchen Sie bestimmte Seiten von uns und das Cookie ist noch nicht abgelaufen, können wir und Google&nbsp; erkennen, dass jemand auf die Anzeige geklickt hat und so zu unserer Seite weitergeleitet wurde. Jeder Kunde von Google AdWords erhält ein anderes Cookie. Daher können Cookies nicht über die Webseiten von verschiedenen AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für uns zu erstellen. Als AdWords-Kunde erfahren wir die Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.</p>
        <p>Wenn Sie nicht möchten, dass Informationen zu Ihrem Verhalten auf der Website im Tracking-Verfahren verarbeitet werden, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „googleadservices.com“ blockiert werden.</p>
        <p>Zudem bietet Ihnen Google die Möglichkeit, die Verwendung von Cookies durch Google für Werbezwecke in den Anzeigenvorgaben zu deaktivieren. Diese finden Sie hier:&nbsp;https://www.google.com/settings/ads/.</p>
        <p>Alternativ können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem Sie die Deaktivierungsseite der Netzwerkwerbeinitiative aufrufen: http://www.networkadvertising.org/choices/.</p>
        <h2>VIII. Analyse</h2>
        <p><strong>1. Allgemeine Informationen zur Analyse</strong></p>
        <p>Für unsere statistischen Analysen verwenden wir Webanalysedienste, die Daten über die Nutzung dieser Seite sammeln.</p>
        <p>Die Analysedienste sammeln Informationen, wie etwa</p>
        <p>Angaben zu Gerät und Browser (Gerätetyp, Betriebssystem, Browserversion);</p>
        <p>IP Adresse;</p>
        <p>Angesehene Websites, URL click stream (Reihenfolge, in der die Seiten angesehen wurden);</p>
        <p>Geographischer Standort;</p>
        <p>Datum und Zeitpunkt des Website-Besuchs;</p>
        <p>Referenzseite, Anwendung oder Dienst;</p>
        <p>Die von den Anbietern bereitgestellten Informationen verwenden wir nur, um die für Sie nützlichsten Informationen zu bestimmen und diese Webseite zu verbessern und zu optimieren. Die mit Hilfe der Analysedienste erhobenen Informationen werden nicht mit personenbezogenen Daten verknüpft.</p>
        <p>Abhängig vom Anbieter werden die über Ihre Nutzung dieser Webseite generierten Daten eventuell in einem Land außerhalb des Europäischen Wirtschaftsraums (EWR) gespeichert und dort verarbeitet, z. B. den Vereinigten Staaten. Weitere Informationen zu den potenziellen Risiken einer grenzüberschreitenden Datenübermittlung finden Sie unter XII. Die Analysedienste erheben lediglich die IP-Adresse, die ihnen beim Besuch dieser Webseite zugeordnet wird, nicht aber Ihren Namen oder sonstige Kennungen. Der Anbieter verwendet diese Informationen, um Ihre Nutzung der Webseite auszuwerten, Berichte über Webseitennutzung zu erstellen und uns andere Leistungen zur Nutzung der Webseite und des Internets zu bieten.</p>
        <p>Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Absatz 1 Satz 1 lit. f DSGVO. Die Verarbeitung beruht auf unserem berechtigten Interesse der Auswertung des Datenverkehrs auf unserer Webseite zur Steigerung Ihres Nutzungserlebnisses und zur allgemeinen Optimierung der Webseite.</p>
        <p><strong>2. Google Analytics</strong></p>
        <p>Wir verwenden den Webanalyse-Service Google Analytics der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Google verwendet die aus dem Cookie gewonnenen Informationen in unserem Auftrag, um Ihre Nutzung der Webseite auszuwerten, Berichte über Webseitennutzung zu erstellen und uns andere Leistungen zur Nutzung der Webseite und des Internets zu bieten.</p>
        <p>Wir haben bei Google Analytics die IP-Anonymisierung aktiviert. Ihre IP-Adresse wird also innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten, die Google besitzt, zusammengeführt.</p>
        <p>Das Setzen eines solchen Cookies können Sie wie folgt verhindern:</p>
        <p>Durch eine entsprechende Einstellung in Ihrem Browser, die das Setzen von Cookies Dritter blockiert (bitte beachten Sie, dass Sie in diesem Fall eventuell nicht die volle Funktionalität der Website nutzen können)</p>
        <p>Sie können die Erhebung und Verarbeitung von Daten über Ihre Nutzung der Website (einschließlich Ihrer IP-Adresse) durch Google auch unterbinden, indem Sie das Browser Plug-In herunterladen und installieren, das Google unter folgendem Link anbietet: http://tools.google.com/dlpage/gaoptout</p>
        <p>Weitere Informationen zum Thema Datenschutz und Google Analytics finden Sie auf der Website von Google: http://www.google.com/analytics/learn/privacy.html?hl=de</p>
        <p><strong>3. Webtrekk</strong></p>
        <p>Folgende Daten werden durch den Einsatz von Pixeln erhoben:</p>
        <p>Request (Dateiname der angeforderten Datei)</p>
        <p>Browsertyp/ -version (Bsp.: Internet Explorer 6.0)</p>
        <p>Browsersprache (Bsp.: Deutsch)</p>
        <p>verwendetes Betriebssystem (Bsp.: Windows XP)</p>
        <p>Javascriptaktivierung</p>
        <p>Java An / Aus</p>
        <p>Cookies An / Aus</p>
        <p>Referrer URL (die zuvor besuchte Seite) IP Adresse – wird umgehend anonymisiert und nach Verarbeitung wieder gelöscht</p>
        <p>Uhrzeit des Zugriffs</p>
        <p>Klicks</p>
        <p>Produkt-Parameter (Bsp.: ID des Produkts/Abonnements, das zum Zugriff berechtigt)</p>
        <p>Diese Cookies dienen lediglich statistischen Zwecken, indem wir zum Beispiel erfahren, welche Inhalte unserer Website am meisten abgerufen werden. Diese Informationen dienen uns dazu, Rubriken wie „meist gelesen“ zu schaffen und auf diesem Weg die Website für unsere Nutzer komfortabler zu gestalten. Durch die statistischen Erkenntnisse zu Browsertyp und Betriebssystem können wir zudem unser Webdesign optimieren.&nbsp;</p>
        <p>Webtrekk speichert IP-Adressen nur in gekürzter (anonymisierter) Form und verwendet sie nur zur Sessionerkennung und für die Geolokalisierung. Die IP-Adresse wird danach umgehend wieder gelöscht. Die durch Webtrekk erhobenen Daten werden ohne Ihre gesondert erteilte Zustimmung nicht dazu benutzt, Sie persönlich zu identifizieren.</p>
        <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>
        <p>Sie können darüber hinaus der Erfassung der oben bezeichneten Daten sowie der Verarbeitung dieser Daten durch Webtrekk jederzeit mit Wirkung für die Zukunft widersprechen, indem Sie das unter dem folgenden Link verfügbare Opt-Out-Cookie setzen:<br/>http://www.webtrekk.com/index/datenschutzerklaerung/opt-out.html</p>
        <p>Dieser Widerspruch gilt so lange, wie Sie das Cookie nicht löschen. Das Cookie ist für die genannte Domain, pro Browser und Rechner gesetzt. Falls Sie daher zum Beispiel unsere Website von zu Hause und der Arbeit oder mit unterschiedlichen Browsern besuchen, müssten Sie von jedem Rechner und Browser das Cookie gesondert setzen.</p>
        <p>Weitere Informationen zum Thema Datenschutz und Webtrekk finden Sie auf der Website von Webtrekk:&nbsp;&nbsp;&nbsp;<br/>https://www.webtrekk.com/de/warum-webtrekk/datenschutz/</p>
        <p><strong>4. Optimizely</strong></p>
        <p>Diese Website verwendet Optimizely, einen Webanalysedienst der Optimizely, Inc., 631 Howard Street, Suite 100 San Francisco, CA 94105 ("Optimizely"). Auch Optimizely verwendet Cookies und Zählpixel. Die durch die Cookies und Pixel erzeugten Informationen über Ihre Benutzung der Website werden in der Regel an einen Server von Optimizely in den USA übertragen und dort gespeichert. In unserem Auftrag wird Optimizely die Informationen verwenden, um Ihre Nutzung der Website auszuwerten und um Reports über die Websiteaktivitäten zusammenzustellen.</p>
        <p>Gespeichert werden insbesondere:</p>
        <p>Summe und Prozentanteil der Nutzer, die eine Webseitenvariante geöffnet haben</p>
        <p>Anzahl Klicks auf Links</p>
        <p>Browsertyp und Version</p>
        <p>Kampagnen-IDs, über die die Website erreicht wurde</p>
        <p>Sie können das Optimizely-Tracking jederzeit deaktivieren (und somit die Erfassung der durch das Cookie und den Pixel erzeugten und auf Ihre Nutzung der Website bezogenen Daten an Optimizely sowie die Verarbeitung dieser Daten durch Optimizely verhindern), indem Sie der Anleitung auf&nbsp;&nbsp;&nbsp;&nbsp;<br/>http://www.optimizely.com/opt_out<br/>folgen.</p>
        <p>Weitere Informationen zum Thema Datenschutz und Optimizely finden Sie auf der Website von Optimizely:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>https://www.optimizely.com/privacy/</p>
        <p><strong>5. Hotjar</strong></p>
        <p>Diese Website verwendet Hotjar, einen Webanalysedienst der Hotjar Ltd, Level 2, St Julians Business Centre, 3 Elia Zammit Street, St Julians STJ 1000, Malta. Die durch die Cookies von Hotjar erzeugten Informationen über Ihre Benutzung der Website werden in der Regel an einen Server von Hotjar übertragen und dort gespeichert. Hotjar wird diese Informationen verwenden, um Ihre Nutzung der Website auszuwerten und um Reports über die Websiteaktivitäten zusammenzustellen.</p>
        <p>Gespeichert werden insbesondere:</p>
        <p>IP-Adresse in anonymisierter Form;</p>
        <p>gesehene Webseiten und Bewegungsmuster auf diesen Seiten;</p>
        <p>Anzahl und Position der Klicks auf Links;</p>
        <p>Browsertyp und Version;</p>
        <p>Bildschirmgröße des genutzten Endgerätes.</p>
        <p>Nicht gespeichert werden personenbezogene Daten oder Bewegungen auf Profilseiten, die personenbezogene Daten enthalten. Insbesondere werden IP-Adressen laut Angaben von Hotjar nur in anonymisierter Form gespeichert. Weitere Informationen zum Thema Datenschutz und Hotjar finden Sie in der Datenschutzerklärung von Hotjar:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>https://www.hotjar.com/privacy.</p>
        <p>Zudem bietet Hotjar die Möglichkeit, der Datenverarbeitung durch das Cookie für die Zukunft zu widersprechen, indem die „Do Not Track“-Funktion von Browsern beachtet wird. Wie Sie diese aktivieren, erfahren Sie hier: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>https://www.hotjar.com/opt-out.</p>
        <p>Wenn Sie nicht möchten, dass Informationen zu Ihrem Verhalten von Hotjar wie oben erläutert verwendet werden, können Sie das hierfür erforderliche automatische Setzen von Cookies generell in Ihren Browsereinstellungen deaktivieren.</p>
        <p><strong>6. Reichweitenmessung, INFOnline</strong></p>
        <p>Unsere Website nutzt das „Skalierbare Zentrale Messverfahren" (SZMnG) der INFOnline GmbH, Forum Bonn Nord - Brühler Straße 9, 53119 Bonn („INFOnline“, https://www.infonline.de) für die Ermittlung statistischer Kennwerte zur Nutzung unserer Angebote. Dabei werden anonyme Messwerte erhoben.</p>
        <p>Ziel der Reichweitenmessung ist es, die Nutzungsintensität, die Anzahl der Nutzer einer Website und das Nutzungsverhalten statistisch – auf Basis eines einheitlichen Standardverfahrens - zu bestimmen und somit marktweit vergleichbare Werte zu erhalten. Für Angebote, die Mitglied der Informationsgemeinschaft zur Feststellung der Verbreitung von Werbeträgern e.V. (IVW – http://www.ivw.eu) sind oder an den Studien der Arbeitsgemeinschaft Online-Forschung e.V. (AGOF - http://www.agof.de) teilnehmen, werden die Nutzungsstatistiken regelmäßig von der AGOF und der Arbeitsgemeinschaft Media-Analyse e.V. (agma - http://www.agma-mmc.de) zu Reichweiten weiter verarbeitet und mit dem Leistungswert „Unique User“ veröffentlicht sowie von der IVW mit den Leistungswerten „Page Impression“ und „Visits“. Diese Reichweiten und Statistiken können auf den jeweiligen Websites eingesehen werden.</p>
        <p><strong>(a) Rechtsgrundlage der Verarbeitung</strong></p>
        <p>Die Messung mittels des Messverfahrens SZMnG durch die INFOnline GmbH erfolgt mit berechtigtem Interesse nach Art. 6 Abs. 1 lit. f) DSGVO.</p>
        <p>Zweck der Verarbeitung der personenbezogenen Daten ist die Erstellung von Statistiken und die Bildung von Nutzerkategorien. Die Statistiken dienen dazu, die Nutzung unseres Angebots nachvollziehen und belegen zu können. Die Nutzerkategorien bilden die Grundlage für eine interessengerechte Ausrichtung von Werbemitteln bzw. Werbemaßnahmen. Zur Vermarktung dieser Webseite ist eine Nutzungsmessung, welche eine Vergleichbarkeit zu anderen Marktteilnehmern gewährleistet, unerlässlich. Unser berechtigtes Interesse ergibt sich aus der wirtschaftlichen Verwertbarkeit der sich aus den Statistiken und Nutzerkategorien ergebenden Erkenntnisse und dem Marktwert unserer Webseite -auch in direktem Vergleich mit Webseiten Dritter- der sich anhand der Statistiken ermitteln lässt.</p>
        <p>Darüber hinaus haben wir ein berechtigtes Interesse daran, die pseudonymisierten Daten der INFOnline, der AGOF und der IVW zum Zwecke der Marktforschung (AGOF, agma) und für statistische Zwecke (INFOnline, IVW) zur Verfügung zu stellen. Weiterhin haben wir ein berechtigtes Interesse daran, die pseudonymisierten Daten der INFOnline zur Weiterentwicklung und Bereitstellung interessengerechter Werbemittel zur Verfügung zu stellen.</p>
        <p><strong>(b) Art der Daten</strong></p>
        <p>Die INFOnline GmbH erhebt die folgenden Daten, welche nach EU-DSGVO einen Personenbezug aufweisen:</p>
        <p>IP-Adresse: Im Internet benötigt jedes Gerät zur Übertragung von Daten eine eindeutige Adresse, die sogenannte IP-Adresse. Die zumindest kurzzeitige Speicherung der IP-Adresse ist aufgrund der Funktionsweise des Internets technisch erforderlich. Die IP-Adressen werden vor jeglicher Verarbeitung um 1 Byte gekürzt und nur anonymisiert weiterverarbeitet. Es erfolgt keine Speicherung oder weitere Verarbeitung der ungekürzten IP-Adressen.</p>
        <p>Einen zufällig erzeugten Client-Identifier: Die Reichweitenverarbeitung verwendet zur Wiedererkennung von Computersystemen alternativ entweder ein Cookie mit der Kennung „ioam.de", ein „Local Storage Object“ oder eine Signatur, die aus verschiedenen automatisch übertragenen Informationen Ihres Browsers erstellt wird. Diese Kennung ist für einen Browser eindeutig, solange das Cookie oder Local Storage Object nicht gelöscht wird. Eine Messung der Daten und anschließende Zuordnung zu dem jeweiligen Client-Identifier ist daher auch dann möglich, wenn Sie andere Webseiten aufrufen, die ebenfalls das Messverfahren („SZMnG“) der INFOnline GmbH nutzen.</p>
        <p>Die Gültigkeit des Cookies ist auf maximal 1 Jahr beschränkt.</p>
        <p><strong>(c) Nutzung der Daten</strong></p>
        <p>Das Messverfahren der INFOnline GmbH, welches auf dieser Webseite eingesetzt wird, ermittelt Nutzungsdaten. Dies geschieht, um die Leistungswerte Page Impressions, Visits und Clients zu erheben und weitere Kennzahlen daraus zu bilden (z.B. qualifizierte Clients). Darüber hinaus werden die gemessenen Daten wie folgt genutzt:</p>
        <p>Eine sogenannte Geolokalisierung, also die Zuordnung eines Webseitenaufrufs zum Ort des Aufrufs, erfolgt ausschließlich auf der Grundlage der anonymisierten IP-Adresse und nur bis zur geographischen Ebene der Bundesländer / Regionen. Aus den so gewonnenen geographischen Informationen kann in keinem Fall ein Rückschluss auf den konkreten Aufenthaltsort eines Nutzers gezogen werden.</p>
        <p>Die Nutzungsdaten eines technischen Clients (bspw. eines Browsers auf einem Gerät) werden webseitenübergreifend zusammengeführt und in einer Datenbank gespeichert. Diese Informationen werden zur technischen Abschätzung der Sozioinformation Alter und Geschlecht verwendet und an die Dienstleister der AGOF zur weiteren Reichweitenverarbeitung übergeben. Im Rahmen der AGOF-Studie werden auf Basis einer zufälligen Stichprobe Soziomerkmale technisch abgeschätzt, welche sich den folgenden Kategorien zuordnen lassen: Alter, Geschlecht, Nationalität, Berufliche Tätigkeit, Familienstand, Allgemeine Angaben zum Haushalt, Haushalts-Einkommen, Wohnort, Internetnutzung, Online-Interessen, Nutzungsort, Nutzertyp.</p>
        <p><strong>(d) Speicherdauer</strong></p>
        <p>Die vollständige IP-Adresse wird von der INFOnline GmbH nicht gespeichert. Die gekürzte IP-Adresse wird maximal 60 Tage gespeichert. Die Nutzungsdaten in Verbindung mit dem eindeutigen Identifier werden maximal 6 Monate gespeichert.</p>
        <p><strong>(e)&nbsp;Weitergabe der Daten</strong></p>
        <p>Die IP-Adresse wie auch die gekürzte IP-Adresse werden nicht weitergegeben. Für die Erstellung der AGOF-Studie werden Daten mit Client-Identifiern an die folgenden Dienstleister der AGOF weitergegeben:</p>
        <p>Kantar Deutschland GmbH (https://www.tns-infratest.com/)</p>
        <p>Ankordata GmbH &amp; Co. KG (http://www.ankordata.de/homepage/)</p>
        <p>Interrogare GmbH (https://www.interrogare.de/)</p>
        <p><strong>(f)&nbsp;Widerspruchsrecht</strong></p>
        <p>Wenn Sie an der Messung nicht teilnehmen möchten, können Sie hier widersprechen: https://optout.ioam.de</p>
        <p>Um einen Ausschluss von der Messung zu garantieren, ist es technisch notwendig, ein Cookie zu setzen. Sollten Sie die Cookies in Ihrem Browser löschen, ist es notwendig, den Opt-Out-Vorgang unter dem oben genannten Link zu wiederholen.</p>
        <p>Weitere Informationen zum Datenschutz im Messverfahren finden Sie auf der Website der INFOnline GmbH (https://www.infonline.de), die das Messverfahren betreibt, der Datenschutzwebseite der AGOF (http://www.agof.de/datenschutz) und der Datenschutzwebseite der IVW (http://www.ivw.eu).</p>
        <h2>IX. Inhalte Dritter und Plug-ins für soziale Netzwerke</h2>
        <p><strong>1.&nbsp;Social Media-Plug-ins</strong></p>
        <p>Wir verwenden folgende Social Media-Plug-ins: AddThis, Facebook, Google+, Xing LinkedIn, Twitter. Durch die Social Media-Plug-ins können Sie mit diesen Netzwerken kommunizieren und Inhalte von unserer Seite aus liken bzw. Kommentare verfassen. Social Media-Plug-ins ermöglichen eine direkte Kommunikation zwischen Ihrem Gerät und den Servern der Anbieter der sozialen Netzwerke. Dadurch können diese mit Ihnen kommunizieren und Informationen über Ihren Besuch auf unserer Webseite sammeln. Diese Verarbeitung beruht auf Art. 6 Absatz 1 Satz 1 lit. f DSGVO. Wir verfolgen mit ihr&nbsp; unser berechtigtes Interesse der Steigerung Ihres Nutzungserlebnisses und der Optimierung unserer Leistungen.</p>
        <p>Eine Übertragung von personenbezogenen Daten findet unabhängig davon statt, ob Sie ein Konto beim Anbieter haben oder nicht.</p>
        <p>Beachten Sie bitte, dass wir weder Einfluss auf den Umfang der vom jeweiligen Plug-in-Anbieter erhobenen personenbezogenen Daten haben, noch wissen, zu welchem Zweck sie verarbeitet oder wie lange sie gespeichert werden.</p>
        <p>Weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten durch die Anbieter finden Sie in dessen entsprechender Datenschutzerklärung. Darüber hinaus erhalten Sie weitere Informationen über Ihre Rechte und Einstellungen zum Datenschutz.</p>
        <p><strong>(a) AddThis” service</strong></p>
        <p>Auf unserer Website verwenden wir den Dienst „AddThis“, der von der AddThis&nbsp; LLC, Inc. 8000 Westpark Drive, Suite 625, McLean, VA 2210, USA („AddThis“) betrieben wird.</p>
        <p>Wenn Sie eine Website unseres Angebots aufrufen, die den Dienst AddThis enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von AddThis her. AddThis erhält die Information, dass Ihr Browser die entsprechende Website aufgerufen hat, speichert zur Identifikation Ihres Browsers ein Cookie auf Ihrem Endgerät und stellt die Social Media Schaltflächen von GooglePlus (vgl. Ziffer 2.1), Facebook (vgl. Ziffer 3.1), XING (vgl. Ziffer 4.1), LinkedIn (vgl. Ziffer 5.1) und Twitter (vgl. Ziffer 6) zur Verfügung, deren Nutzung von AddThis getrackt wird. Die so erzeugten Daten werden an die AddThis in den USA übertragen und dort verarbeitet.</p>
        <p>Auf die Datenverarbeitung durch AddThis haben wir keinen Einfluss. Nähere Informationen zu den erhobenen Daten und deren Verarbeitung durch AddThis LLC finden Sie unter www.addthis.com/privacy</p>
        <p>Wenn Sie der Datenverarbeitung durch AddThis für die Zukunft widersprechen möchten, können Sie ein Opt-Out-Cookie setzen, das Sie unter folgendem Link herunterladen können: http://www.addthis.com/privacy/opt-out</p>
        <p><strong>(b) Google Plus</strong></p>
        <p>Unsere Website verwendet die „+1″-Schaltfläche des sozialen Netzwerkes Google Plus, welches von der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“) betrieben wird. Mit einem Klick auf die „+1“-Schaltfläche können Sie Inhalte von unserer Website mit Ihren Kontakten bei Google Plus teilen.</p>
        <p>Wenn Sie eine Website unseres Internetauftritts aufrufen, die eine solche Schaltfläche enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Google auf. Der Inhalt der „+1″-Schaltfläche wird von Google direkt an Ihren Browser übermittelt und von Google in die Website eingebunden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Google mit der Schaltfläche erhebt. Laut Google werden ohne einen Klick auf die Schaltfläche keine personenbezogenen Daten erhoben. Nur bei eingeloggten Mitgliedern, werden solche Daten, unter anderem Informationen über Ihr Google-Profil, die von Ihnen empfohlene URL, Ihre IP-Adresse und andere browserbezogene Informationen erhoben und verarbeitet.</p>
        <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte Googles Datenschutzhinweisen zu der „+1″-Schaltfläche:&nbsp;https://support.google.com/plus/answer/1319578?hl=de</p>
        <p>Wenn Sie Google Plus-Mitglied sind und nicht möchten, dass Google über unsere Website Daten über Sie sammelt und mit Ihren bei Google gespeicherten Mitgliedsdaten verknüpft, müssen Sie sich vor Ihrem Besuch unseres Internetauftritts bei Google Plus ausloggen.</p>
        <p>Weitere Informationen zum Umgang mit personenbezogenen Daten durch Google erhalten Sie in der Datenschutzrichtlinie: https://www.google.com/intl/de/policies/privacy/</p>
        <p><strong>(c) Facebook</strong></p>
        <p>Unsere Website verwendet die „Teilen″-Schaltfläche des sozialen Netzwerkes Facebook, welches von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland („Facebook“) betrieben wird. Mit einem Klick auf die „Teilen“-Schaltfläche können Sie Inhalte von unserer Website mit Ihren Freunden bei Facebook teilen.</p>
        <p>Wenn Sie eine Website unseres Internetauftritts aufrufen, die eine solche Schaltfläche enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt der „Teilen″-Schaltfläche wird von Facebook direkt an Ihren Browser übermittelt und von Facebook in die Website eingebunden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit der Schaltfläche erhebt. Facebook sammelt nach eigenen Angaben Informationen, wenn Sie Webseiten Dritter besuchen, die Dienste von Facebook (wie etwa die „Teilen“-Schaltfläche) nutzen. Dazu zählen Informationen über die von Ihnen besuchten Webseiten und über Ihre Nutzung Dienste von Facebook auf solchen Webseiten. Wenn Sie bei Ihrem Besuch unserer Website gleichzeitig bei Facebook angemeldet sind, erhält Facebook unter anderem Ihre Nutzer-ID, die besuchte Website, das Datum und die Uhrzeit sowie andere browserbezogene Informationen.</p>
        <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook entnehmen Sie bitte Facebook‘s Hinweisen zu sozialen Plug-ins: https://de-de.facebook.com/help/443483272359009/</p>
        <p>Weitere Informationen zum Umgang mit personenbezogenen Daten durch Facebook erhalten Sie in der Datenschutzrichtlinie: https://de-de.facebook.com/about/privacy/</p>
        <p><strong>(d) XING</strong></p>
        <p>Unsere Website verwendet die „Teilen″-Schaltfläche des sozialen Netzwerkes XING, welches von der XING AG, Dammtorstraße 29-32, 20354 Hamburg („XING“) betrieben wird. Mit einem Klick auf die „Teilen“-Schaltfläche können Sie Inhalte von unserer Website mit Ihren Kontakten bei XING teilen.</p>
        <p>Wenn Sie eine Website unseres Internetauftritts aufrufen, die eine solche Schaltfläche enthält, baut Ihr Browser noch keine direkte Verbindung mit den Servern von XING auf. Der Inhalt der „Teilen″-Schaltfläche und weitere Informationen zur besuchten Website werden erst dann an XING übermittelt, wenn Sie die „Teilen“-Schaltfläche aktiv nutzen. Wir haben keinen Einfluss auf den Umfang der Daten, die XING über die Schaltfläche erhebt.</p>
        <p>Weitere Informationen zum Datenschutz und der „Teilen“-Schaltfläche von XING finden Sie hier: https://dev.xing.com/plugins/share_button/docs</p>
        <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch XING entnehmen Sie bitte der Datenschutzerklärung von XING: https://www.xing.com/privacy</p>
        <p><strong>(e) LinkedIn</strong></p>
        <p>Unsere Website verwendet die „Share″-Schaltfläche des sozialen Netzwerkes LinkedIn, welches von der LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA ("LinkedIn") betrieben wird. Wenn Sie unsere Website besuchen, wird über die „Share″-Schaltfläche eine direkte Verbindung zwischen Ihrem Browser und dem LinkedIn-Server hergestellt. LinkedIn erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Website besucht haben. Wenn Sie die „Share″-Schaltfläche anklicken während Sie in Ihrem LinkedIn-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem LinkedIn-Profil teilen. Dadurch kann LinkedIn den Besuch unserer Website Ihrem Benutzerkonto zuordnen.</p>
        <p>Wir haben keinen Einfluss auf den Umfang der Daten, die LinkedIn über die Schaltfläche erhebt. Details zur Datenerhebung (Zweck, Umfang) sowie einer weiteren Verarbeitung und Nutzung der Daten können Sie den Datenschutzhinweisen von LinkedIn entnehmen. Diese Hinweise finden Sie unter: https://www.linkedin.com/legal/privacy-policy</p>
        <p><strong>(f) Twitter</strong></p>
        <p>Unsere Website verwendet die „Link teilen″-Schaltfläche des sozialen Netzwerkes Twitter, welche von der Twitter International Company, The Academy, 42 Pearse Street, Dublin 2, Ireland („Twitter“) betrieben wird. Mit einem Klick auf die „Link teilen“-Schaltfläche können Sie Inhalte von unserer Website mit Ihren Followern bei Twitter teilen.</p>
        <p>Wenn Sie eine Website unseres Internetauftritts aufrufen, die eine solche Schaltfläche enthält, baut Ihr Browser eine direkte Verbindung mit den Servern von Twitter auf. Twitter erhält hierdurch Log-Daten, die die Website beinhalten, die Sie besucht haben und setzt einen Cookie, der Ihren Browser identifiziert. Wenn Sie die „Link teilen″-Schaltfläche anklicken während Sie in Ihrem Twitter-Account eingeloggt sind, können Sie die Inhalte unserer Website auf Ihrem Twitter-Profil teilen. Dadurch kann Twitter den Besuch unserer Website Ihrem Benutzerkonto zuordnen. Wir haben keinen Einfluss auf den Umfang der Daten, die Twitter über die Schaltfläche erhebt.</p>
        <p>Informationen über Tweets, Log-Daten und andere Informationen, die Twitter über die „Link teilen“-Schaltfläche sammelt, erhalten Sie in der Datenschutzrichtlinie von Twitter: https://twitter.com/privacy?lang=de</p>
        <p><strong>2.&nbsp;Drittanbieter</strong></p>
        <p><strong>(a) VideoManager</strong></p>
        <p>Wir nutzen den Dienst „VideoManager“, der von der MovingIMAGE24 GmbH, Stralauer Allee 7, 10245 Berlin („Movingimage“) betrieben wird, um Videos technisch korrekt auf der Website darstellen und an Sie ausspielen zu können. Diese Verarbeitung beruht auf Art. 6 Absatz 1 Satz 1 lit. f DSGVO. Wir verfolgen mit ihr unser berechtigtes Interesse der Steigerung Ihres Nutzungserlebnisses und der Optimierung unserer Leistungen.</p>
        <p>MovingImage setzt auf Ihrem Gerät einen Cookie, in dem bestimmte Informationen zur Darstellung und zur Widergabe von Videos gespeichert und an MovingImage übermittelt werden. Wir haben keinen Einfluss auf die Datenverarbeitung durch MovingImage. Weitere Informationen finden Sie in der Datenschutzerklärung von MovingImage: https://www.movingimage24.com/de/datenschutz/&nbsp;<br/>Dort erhalten Sie Informationen über Ihre Rechte und Einstellungen zum Datenschutz.</p>
        <p><strong>(b) Umfragen</strong></p>
        <p>Zu gegebenen Zeiten führen wir auf unserer Website Umfragen unter unseren Nutzern zu verschiedenen Themen durch. Die Teilnahme an diesen Nutzerumfragen ist für sie freiwillig. Zur Erhebung und Auswertung der Daten verwenden wir das Umfrage Tool SurveyMonkey der SurveyMonkey Europe UC 2nd Floor, 2 Shelbourne Buildings, Shelbourne Road, Dublin, Ireland.</p>
        <p>Hierzu werden die Daten an die Server von SurveyMonkey in den USA übermittelt und dort nur auf unsere Weisung hin verarbeitet. SurveyMonkey nimmt dabei am EU-US-Privacy-Shield teil, so dass sichergestellt ist, dass Ihre Daten auf einem der EU gleichwertigen Niveau verarbeitet werden. Mehr Informationen zur Sicherheit und Verwendung von Daten bei SurveyMonkey finden Sie unter:<br/>https://www.surveymonkey.de/mp/policy/privacy-policy/.</p>
        <p><strong>(c) Feedback</strong></p>
        <p>Auf unserer Website bieten wir Ihnen eine Feedback Funktion des Anbieters UserVoice Inc., 121 2nd St. Floor 4, San Francisco, CA 94105 (USA), an. Über diesen Dienst erhobene Informationen werden auf Servern innerhalb der USA verarbeitet. Der Anbieter UserVoice Inc. ist bei dem "Privacy Shield"-Programm des US-Handelsministeriums registriert und beachtet die Datenschutzbestimmungen dieses Programms.</p>
        <p>Nähere Informationen zur Selbst-Zertifizierung von User Voice und Ihren Rechten im Rahmen des „Privacy Shield“-Programms erhalten Sie hier: https://www.uservoice.com/privacy-shield/.</p>
        <p>Wenn Sie den UserVoice-Button betätigen, baut Ihr Browser eine direkte Verbindung mit den Servern von UserVoice auf und die von Ihnen eingegebenen Daten werden dort gespeichert. Wir erhalten eine Benachrichtigung durch UserVoice, dass eine Kontaktanfrage eingegangen ist und beantworten diese über die IT-Infrastruktur von UserVoice.</p>
        <p>Details zum Umgang mit Ihren personenbezogenen Daten durch UserVoice finden Sie unter http://uservoice.com/privacy.</p>
        <p>Sie können selbst entscheiden, ob und inwieweit Sie uns personenbezogene Daten über die Feedback Funktion zukommen lassen wollen. Personenbezogene Daten, die wir im Rahmen Ihres Feedbacks erhalten, werden wir nur für den Zweck der Kommunikation mit Ihnen nutzen und um Ihr Anliegen bearbeiten zu können.</p>
        <p><strong>(d) reCAPTCHA</strong></p>
        <p>Um unsere Websites vor Spam zu schützen, nutzen wir in bestimmten Fällen den Service reCAPTCHA der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google”). Dies dient zur Unterscheidung, ob die Eingabe durch einen Menschen erfolgt oder missbräuchlich durch maschinelle Verarbeitung. Mit der Abfrage wird Ihre IP-Adresse und gegebenenfalls weitere von Google für den Dienst benötigte Daten an Google gesendet. Hierfür gelten die abweichenden Datenschutzbestimmungen von Google Inc. Weitere Informationen zu reCAPTCHA und den Datenschutzrichtlinien von Google Inc. finden Sie unter&nbsp;https://www.google.com/recaptcha&nbsp;und&nbsp;https://www.google.com/intl/de/policies/privacy/</p>
        <p><strong>3. Links zu Webseiten Dritter</strong></p>
        <p>Diese Webseite umfasst ggf. Links zu Webseiten Dritter. Wir sind nicht verantwortlich für den Inhalt und die Datenverarbeitung auf den entsprechenden Webseiten Dritter. Informationen zur Datenverarbeitung auf den entsprechenden Webseiten finden Sie in der Datenschutzerklärung der entsprechenden Webseiten.</p>
        <p><strong>XI. Weitergabe personenbezogener Daten an Dritte</strong></p>
        <p>Ihre personenbezogenen Daten oder Teile davon werden zu den oben genannten Zwecken folgenden Dritten offengelegt:</p>
        <p>Wenn Sie im Rahmen der Registrierung eine entsprechende Einwilligung erteilt haben, übermitteln wir Ihren Vor- und Nachnamen, Ihren Wohnort und Ihre E-Mail-Adresse für Zwecke des Versands von Fachinformationen an:</p>
        <p>Springer Customer Service Center GmbH (SCSC), Tiergartenstr. 15-17, 69121&nbsp;Heidelberg, Germany.</p>
        <p>Ihre Einwilligung können Sie jederzeit, mit Wirkung für die Zukunft, widerrufen, etwa per Mail an support@aok.pflegenzuhause.de oder per Brief an Springer Medizin Verlag GmbH, Heidelberger Platz 3, 14197 Berlin.</p>
        <p>Die Rechtsgrundlage für diese Übermittlung Ihrer personenbezogenen Daten sind Art. 6 Absatz 1 Satz 1 lit. b und f DSGVO Wir verfolgen mit ihr&nbsp; unser berechtigtes Interesse der Steigerung Ihres Nutzungserlebnisses und der Optimierung unserer Leistungen.</p>
        <p>Wir geben ggf. anonyme aggregierte Statistiken über die Nutzer unserer Webseiten weiter, um potenziellen Partnern,&nbsp; Werbenden oder anderen seriösen Dritten unsere Dienste beschreiben zu können sowie zur Erfüllung anderer legalen Zwecke. Diese Statistiken umfassen aber keine personenbezogenen Daten.</p>
        <p>Ihre personenbezogenen Daten werden ggf. folgenden Auftragnehmern offengelegt, die uns bei der Erbringung unserer Leistungen unterstützen:</p>
        <p>Mapp Digital Germany GmbH, Nymphenburger Höfe NY2, 80335 München, Dienstleister für den Versand von Newslettern;</p>
        <p>2bMore B.V., Floridalaan 6, NL-3404 WV IJsselstein</p>
        <p>Optimizely Inc., 631 Howard Street, Suite&nbsp;100 San&nbsp;Francisco, CA 94105</p>
        <p>Webtrekk GmbH, Robert-Koch-Platz&nbsp;4, D-10115 Berlin</p>
        <p>Springer Customer Service Center GmbH, Tiergartenstr. 15-17, 69121&nbsp;Heidelberg, Germany (für Europa, Mittlerer Osten, Afrika, Asien, Ozeanien) oder Springer Customer Service Center LLC, 233&nbsp;Spring St, New &nbsp;York, NY, 10013&nbsp;United&nbsp;States (für Nordamerika, Zentralamerika, Südamerika) Betreiber des Online-Shops, um den Bestellprozess durchzuführen.</p>
        <p>Im Falle einer Umstrukturierung oder eines Verkaufs unseres Unternehmens an einen Dritten ist die Übermittlung Ihrer personenbezogenen Daten in Einklang mit geltendem Recht&nbsp; an die umstrukturierte Gesellschaft oder den Dritten möglich.</p>
        <p>Falls wir dazu gesetzlich berechtigt oder verpflichtet sind (etwa aufgrund geltenden Rechts oder einer gerichtlichen Verfügung), können wie Ihre personenbezogenen Daten offenlegen.</p>
        <h2>X. Übermittlung personenbezogener Daten in Drittstaaten</h2>
        <p>Im Rahmen des oben genannten Umfangs ist es möglich, dass wir Ihre personenbezogenen Daten in andere Staaten übermitteln (einschließlich Staaten außerhalb des EWR), in denen ggf. andere Datenschutzstandards als an Ihrem Wohnort gelten. Beachten Sie dabei bitte, dass in anderen Staaten verarbeitete Daten ggf. ausländischen Gesetzen unterliegen und den dortigen Regierungen, Gerichten sowie Strafverfolgungs- und Aufsichtsbehörden zugänglich sind. Bei der Übertragung Ihrer personenbezogenen Daten in solche Staaten werden wir jedoch geeignete Maßnahmen treffen, um Ihre Daten angemessen zu sichern.</p>
        <p>Bei der Übertragung in einen Staat außerhalb des EWR ist die Übertragung geschützt durch internationale Abkommen wie das EU-US-Privacy-Shield sowie EU-Standardvertragsklauseln. Für weitere Informationen zu den oben genannten Schutzmaßnahmen wenden Sie sich per E-Mail an den Konzerndatenschutzbeauftragten unter dataprotection@springer.com.</p>
        <h2>XI. Sicherheit</h2>
        <p>Wir verfügen über angemessene, modernste Sicherheitsmaßnahmen, um Ihre Daten vor Verlust, Zweckentfremdung und Veränderung zu schützen. So werden etwa unsere Sicherheitsrichtlinien und Datenschutzerklärungen regelmäßig überprüft und bei Bedarf verbessert. Zudem haben nur befugte Mitarbeiter Zugriff auf personenbezogene Daten. Obwohl wir nicht gewährleisten oder garantieren können, dass es nie zu Verlust, einer Zweckentfremdung oder Veränderung von Daten kommen wird, tun wir alles in unserer Macht Stehende, um dies zu verhindern.</p>
        <p>Bitte bedenken Sie, dass eine Datenübermittlung über das Internet nie vollkommen sicher ist. Die Sicherheit der auf unserer Webseite eingegebenen Daten können wir während der Übermittlung über das Internet nicht garantieren. Dies geschieht auf Ihr eigenes Risiko.</p>
        <h2>XII. Speicherfristen</h2>
        <p>Unser Ziel ist es, Ihre personenbezogenen Daten nur im geringstmöglichen Umfang zu verarbeiten. Sind in dieser Erklärung keine genauen Speicherfristen angegeben, werden wir Ihre personenbezogenen Daten nur so lange speichern, wie sie für die Erfüllung des Zweckes, für die sie ursprünglich erhoben wurden, nötig sind und – falls zutreffend – solange wie gesetzlich vorgeschrieben.</p>
        <h2>XIII. Ihre Rechte</h2>
        <p>Sie haben, je nach den Gegebenheiten des konkreten Falls, folgende Datenschutzrechte:</p>
        <p>Zugang zu Ihren personenbezogenen Daten und/oder Kopien dieser Daten zu verlangen. Dies schließt Auskünfte über den Zweck der Nutzung, die Kategorie der genutzten Daten, deren Empfänger und Zugriffsberechtigte sowie, falls möglich, die geplante Dauer der Datenspeicherung oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer, ein;</p>
        <p>die Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit deren Nutzung datenschutzrechtlich unzulässig ist, insbesondere, weil (i) die Daten unvollständig oder unrichtig sind, (ii) sie für die Zwecke, für die sie erhoben wurden nicht mehr notwendig sind, (iii) die Einwilligung, auf die sich die Verarbeitung stützte widerrufen wurde, oder (iv) Sie erfolgreich von einem Widerspruchsrecht zur Datenverarbeitung Gebrauch gemacht habe; in Fällen, in denen die Daten von dritten Parteien verarbeitet werden, werden wir Ihre Anträge auf Berichtigung, Löschung&nbsp; oder Einschränkung der Verarbeitung an diese dritten Parteien weiterleiten, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden;</p>
        <p>die Einwilligung zu verweigern oder – ohne Auswirkung auf die Rechtmäßigkeit der vor dem Widerruf erfolgten Datenverarbeitungen – Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widerrufen;</p>
        <p>nicht einer ausschließlich auf einer automatisierten Verarbeitung beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder in ähnlicher Weise erheblich beeinträchtigt;</p>
        <p>die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu verlangen und diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln; Sie haben gegebenenfalls auch das Recht zu verlangen, dass wir die personenbezogenen Daten direkt einem anderen Verantwortlichen übermitteln, soweit dies technisch machbar ist;</p>
        <p>rechtliche Maßnahmen zu ergreifen oder die zuständigen Aufsichtsbehörden anzurufen, wenn Sie der Ansicht sind, dass Ihre Rechte infolge einer nicht im Einklang mit den datenschutzrechtlichen Vorgaben stehenden Verarbeitung Ihrer personenbezogenen Daten verletzt wurden.</p>
        <p>Zusätzlich hierzu steht Ihnen das Recht zu, der Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widersprechen:</p>
        <p>sofern wir Ihre personenbezogenen Daten zu Zwecken der Direktwerbung verarbeiten; oder</p>
        <p>sofern wir Ihre personenbezogenen Daten zur Verfolgung unserer berechtigten Interessen verarbeiten und Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben.</p>
        <p>Sie können (i) die oben genannten Rechte ausüben oder (ii) Fragen stellen oder (iii) Beschwerde gegen die durch uns erfolgende Verarbeitung Ihrer personenbezogenen Daten einlegen, indem Sie uns, wie weiter unten angegeben, kontaktieren.</p>
        <h2>XIV. Kontakt</h2>
        <p>Fragen, Bedenken oder Kommentare zu dieser Datenschutzerklärung bzw. Anfragen zu Ihren personenbezogenen Daten können Sie gerne per E-Mail an unseren Konzerndatenschutzbeauftragten richten. Unseren Konzerndatenschutzbeauftragten erreichen Sie unter dataprotection@springer.com.</p>
        <p>Die Informationen, die Sie uns bei Ihrer Kontaktaufnahme zur Verfügung stellen&nbsp;(support@aok.pflegenzuhause.de oder schriftlich an die Springer Medizin Verlag GmbH, z.Hd. Kundenservice Springer Medizin, Heidelberger Platz 3, 14197 Berlin), werden nur verarbeitet, um Ihre Anfrage zu bearbeiten. Sie werden im Anschluss daran unverzüglich gelöscht; alternativ dazu schränken wir die Verarbeitung Ihrer personenbezogenen Daten entsprechend der gesetzlichen Vorschriften zu Aufbewahrungspflichten ein.</p>
        <p>XV. Änderung dieser Datenschutzerklärung</p>
        <p>Wir behalten uns vor, diese Datenschutzerklärung entsprechend der Aktualisierung unserer Webseite jeweils zu ändern. Besuchen Sie diese Webseite bitte regelmäßig und sehen Sie sich die jeweils aktuelle Datenschutzerklärung an. Diese Datenschutzerklärung wurde zuletzt aktualisiert am 23.11.2022.</p>


        </div>


    </article>
)
