import React, {useState} from 'react';
import { RegistrationForm } from "./RegistrationForm";
import { Header } from "./header";
import { Footer }  from "./footer";
import './App.scss';
import {Impressum} from "./impressum";
import { NutzungsBedingungen } from './bedingungen';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'
import { Datenschutz } from './datenschutz';
import { Cookies } from './cookies';

function App() {
    const [contrastEnabled, setContrastEnabled] = useState(false);
    const toggleContrast = () => {
        setContrastEnabled(!contrastEnabled);
    };
    const [isSubmitted, setIsSubmitted] = useState(false);


   const  home = () => (
        <div id="main" className={isSubmitted ? 'isSubmitted' : ''}>
            <div className="marginSpacer registration hidden-xs hidden-sm "/>
            <RegistrationForm isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted}/>
        </div>
    );

    const impressum = () => (
        <div className="Impressum">
            <div className="marginSpacer hidden-xs hidden-sm "/>
            <Impressum />
        </div>
    );
    const nutzungs = () => (
        <div className="bedingungen">
            <div className="marginSpacer" />
            <NutzungsBedingungen />
        </div>
    )
    const datenschutz = () => (
        <div className="bedingungen">
            <div className="marginSpacer" />
            <Datenschutz />
        </div>
    )
    const cookies = () => (
        <div className="bedingungen">
            <div className="marginSpacer" />
            <Cookies />
        </div>
    )

        return (
            <div className={contrastEnabled ? 'contrast' : ''}>
                <Router>
                    <Header isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} toggleContrast={toggleContrast}/>
                    <Route exact path="/" component={home} />
                    <Route path="/impressum" component={impressum} />
                    <Route path="/bedingungen" component={nutzungs} />
                    <Route path="/datenschutz" component={datenschutz} />
                    <Route path="/cookies" component={cookies} />
                    <Footer/>
                </Router>

            </div>
        )
}

export default App;
