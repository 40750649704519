import React from 'react';
import './impressum.css';
import {Helmet} from "react-helmet";

export const Impressum = (props) => {
    return (
        <div className="container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Impressum</title>
            </Helmet>
            <div className="aok-link">
                <a href="/pk/niedersachsen/" itemProp="url" className="breadcrumb__link" title="aok.de">
                    <span itemProp="title">aok.de</span>
                </a>
            </div>
            <div>
                <article id="article_2598" className="article">
                    <h1>Impressum „Pflegen zu Hause“</h1>
                    <p>Das Webangebot „Pflegen zu Hause“ wird von der Springer Medizin Verlag
                        GmbH, Heidelberger Platz 3, 14197 Berlin, in Kooperation mit der AOK-Verlag
                        GmbH betrieben.
                        <br/>
                        <br/>
                        Inhaber der journalistisch-redaktionellen Inhalte dieser Webseite ist die Springer
                        Medizin Verlag GmbH. Die Springer Medizin Verlag GmbH ist auch für die
                        Gestaltung und technische Umsetzung dieses Webangebots verantwortlich.
                    </p>
                    <div>
                        <div className="content-text ">
                            <div className="content-row">
                                <div className="content-text__title">
                                    <h3 className="h4">Verantwortlich für den Inhalt</h3>
                                </div>
                                <div className="content-text__content">
                                    <div className="content-text__paragraph">
                                        <p>
                                            Redaktion:
                                        </p>
                                        <p>
                                            Nicoletta Eckardt, Dr. Carsten Müssig
                                        </p>
                                    </div>
                                    <div className="content-text__paragraph">
                                        <p>
                                            Redaktionelle Mitarbeit:
                                        </p>
                                    </div>
                                        <div className="content-text__paragraph">
                                            <p>
                                                AOK Nordost:
                                            </p>
                                            <p>
                                                Dr. Katharina Graffmann-Weschke<br/>
                                                Annegret Paelecke<br/>
                                                Andrea Schober
                                            </p>
                                        </div>
                                    <div className="content-text__paragraph">
                                        <p>
                                            AOK Bayern:
                                        </p>
                                        <p>
                                            Dr. Marianna Hanke-Ebersoll<br/>
                                            Daniela Kowalzyk<br/>
                                            Kerstin Sümmerer
                                        </p>
                                    </div>
                                    <div className="content-text__paragraph">
                                        <p>
                                            AOK Niedersachsen:
                                        </p>
                                        <p>
                                            Jan Malke
                                        </p>
                                    </div>
                                    <div className="content-text__paragraph">
                                        <p>
                                            E-Learning:
                                        </p>
                                        <p>
                                            Dr. Carsten Müssig
                                        </p>
                                    </div>
                                    <div className="content-text__paragraph">
                                        <p>
                                            Projektmanagement:
                                        </p>
                                        <p>
                                            Inga Hoffmann
                                        </p>
                                    </div>
                                    <div className="content-text__paragraph">
                                        <p>
                                            Illustrationen:
                                        </p>
                                        <p>
                                            Eva Künzel, www.evakuenzel.de
                                        </p>
                                    </div>
                                    <div>
                                        <a href="https://www.pflegenzuhause.de">www.pflegenzuhause.de</a><br/>
                                        <a href="https://www.springerpflege.de">www.springerpflege.de</a><br/>
                                        <p>Tel: 0800 7780 777 (kostenfrei) | +49 (0) 30 827 875 566<br/>
                                            Email: support@aok.pflegenzuhause.de
                                        </p>
                                    </div>
                                    <div>
                                        <p>Amtsgericht Berlin-Charlottenburg, HRB 167094 B<br/>
                                            Geschäftsführer: Fabian Kaufmann, Dr. Cécile Mack und Dr. Hendrik Pugge<br/>
                                            USt.-ID: DE230026696
                                        </p>
                                    </div>
                                    <div>
                                        <p>Verantwortlicher im Sinne des § 18 Abs. 2 Medienstaatsvertrag<br/>
                                        Dr. Hendrik Pugge, Springer Medizin Verlag GmbH, Heidelberger Platz 3, 14197 Berlin
                                        </p>
                                    </div>
                                    <div className="content-text__paragraph-test">
                                        <p><u>Kooperationspartner:</u><br/>
                                            AOK-Verlag GmbH<br/>
                                            Lilienthalstraße 1-3<br/>
                                            53424 Remagen
                                        </p>
                                    </div>
                                    <div>
                                        <a href="https://www.aok-verlag.de">www.aok-verlag.de</a>
                                        <p>Tel: +49 (0) 2642 931-0<br/>
                                            Fax: +49 (0) 2642 931-130<br/>
                                            E-Mail: fachinfo@aok-verlag.de
                                        </p>
                                    </div>
                                    <div>
                                        <p>Amtsgericht Koblenz, HRB 13100<br/>
                                            Geschäftsführer: Frank Schmidt<br/>
                                            USt.-ID: DE122263947
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
}
