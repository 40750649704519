import React from 'react';

export const NutzungsBedingungen = () => (<
    article className="container detail-content is-centered with-in-site-navigation">
    <h1>Allgemeine Nutzungs- und Geschäftsbedingungen</h1>
    <div className="rich-text">
        <h2>für die Plattform „pflegenzuhause.de“</h2>
        <h2>&nbsp;</h2>
        <p>
            <strong>1.&nbsp;&nbsp; &nbsp;Geltungsbereich</strong>
        </p>
        <p>1.1&nbsp;&nbsp; &nbsp;Anbieter des Fortbildungsportals für pflegende Angehörige „pflegenzuhause.de“ ist die Springer Medizin Verlag GmbH, Heidelberger Platz 3, D-14197 Berlin, Telefon:&nbsp;+49 (0)30 827 875 566, Fax Zentrale: +49 (0) 30 827 87 5570, Email:&nbsp;support@aok.pflegenzuhause.de, Handelsregister Amtsgericht Berlin-Charlottenburg HRB 167094 B, Geschäftsführer: Fabian Kaufmann, Dr. Cécile Mack, Dr. Hendrik Pugge (im Folgenden als „Springer“ bezeichnet).</p>
        <p>1.2&nbsp;&nbsp; &nbsp;Die nachfolgenden Allgemeinen Nutzungs- und Geschäftsbedingungen gelten für sämtliche im und über das Internetportal „pflegenzuhause.de“ bzw. „akademie.pflegenzuhause.de“ (im Folgenden als das „Portal“ bezeichnet) und dessen Unterseiten von Springer angebotenen und erbrachten Leistungen (physische Produkte, z.B. Lernboxen), digitale Inhalte (z.B. E-Learning-Kurse) und Dienstleistungen.</p>
        <p>1.3&nbsp;&nbsp; &nbsp;Die nachfolgenden Allgemeinen Nutzungs- und Geschäftsbedingungen gelten ausschließlich. Abweichende Vertragsbedingungen des Nutzers gelten nur, wenn sie von Springer bestätigt wurden.</p>
        <p><strong>2.&nbsp;&nbsp; &nbsp;Leistungen</strong></p>
        <p>2.1&nbsp;&nbsp; &nbsp;Springer stellt mit „pflegenzuhause.de“ einen Dienst für pflegende Angehörige zur Verfügung. In dem Portal kann der E-Learning-Kurs "Pflegen zu Hause" (inklusive Print-Inhalte) bezogen werden (vgl. hierzu Ziffer 5.). Das Portal steht abgesehen von allgemein gehaltenen Inhalten nur den Personen offen, die sich zuvor ordnungsgemäß registriert haben.</p>
        <p>Die Einzelheiten der jeweils angebotenen Leistungen von Springer sind auf dem Portal unter www.aok.pflegenzuhause.de im Einzelnen beschrieben.</p>
        <p>2.2&nbsp;&nbsp; &nbsp;Der Abruf der Leistungen von bzw. Bestellungen bei Springer setzt eine Internetverbindung sowie die Erlaubnis zur Verwendung von Javascript im Browser in der jeweils aktuellsten Version voraus. Für den Bezug von Downloads im PDF-Format ist außerdem das Programm Adobe Reader der Adobe Systems Inc. in der jeweils aktuellen Version oder ein anderes Programm, das das Betrachten und Speichern von PDF-Dokumenten ermöglicht, erforderlich. Für die Einrichtung dieser technischen Voraussetzungen ist der Nutzer selbst verantwortlich. Hierfür anfallende Kosten sind von dem Nutzer zu tragen.</p>
        <p>3.&nbsp;&nbsp; &nbsp;Registrierung, Zugang zum Portal, Zugangsdaten</p>
        <p><strong>3.1&nbsp;&nbsp; &nbsp;Registrierung</strong></p>
        <p>Die Nutzung der Angebote auf „pflegenzuhause.de“ setzt die vorherige Verifizierung und Registrierung des Nutzers sowie die Zuweisung und Freischaltung der erforderlichen Zugangsdaten, insbesondere eines Passwortes voraus.</p>
        <p>3.2&nbsp;&nbsp; &nbsp;Zugang zu dem Portal, Gebrauch der Zugangsdaten</p>
        <p>3.2.1&nbsp;&nbsp; &nbsp;Der Nutzer erhält Zugang zu den geschlossenen Bereichen des Portals „pflegenzuhause.de“ und seinen Leistungen, indem er seine im Rahmen der Registrierung generierten Zugangsdaten in das Login-Formular eingibt.</p>
        <p>3.2.2&nbsp;&nbsp; &nbsp;Die Zugangsdaten sind – wie auch die zur Verfügung gestellten Leistungen selbst – ausschließlich für die persönliche Nutzung durch den betreffenden Nutzer bestimmt und dürfen nicht an Dritte weitergeben werden. Springer hat das Recht, eine zentrale Sperrung der Zugangsdaten für den Nutzer vorzunehmen. Der Nutzer haftet ferner auch gegenüber Springer für alle Folgen der Drittnutzung, sofern der Missbrauch der Zugangsdaten von ihm zu vertreten ist. Zu vertreten hat der Nutzer den Missbrauch insbesondere bereits, wenn er die unbefugte Nutzung der Zugangsdaten auch nur fahrlässig ermöglich hat. Die Haftung endet erst, wenn der Nutzer Springer über die unberechtigte Nutzung informiert und, falls erforderlich, das Passwort geändert hat.</p>
        <p>3.3&nbsp;&nbsp; &nbsp;Ende und Entziehung der Zugangsberechtigung</p>
        <p>3.3.1&nbsp;&nbsp; &nbsp;Springer behält es sich vor, den Zugang des Nutzers bei Verstößen gegen diese Allgemeinen Nutzungs- und Geschäftsbedingungen, insbesondere wegen</p>
        <p>•&nbsp;&nbsp; &nbsp;falscher Angaben bei der Anmeldung,<br />•&nbsp;&nbsp; &nbsp;unbefugter Weitergabe der Zugangsdaten, insbesondere des Passwortes,</p>
        <p>ohne Angabe von Gründen zeitweilig oder dauerhaft zu sperren und/oder dem Nutzer den Zugang mit sofortiger Wirkung oder mit im Ermessen von Springer stehender Frist endgültig zu entziehen und das Vertragsverhältnis außerordentlich und fristlos zu kündigen.</p>
        <p>3.3.2&nbsp;&nbsp; &nbsp;Wird dem Nutzer die Zugangsberechtigung zeitweilig oder endgültig entzogen oder erlischt sie, kann der Nutzer die im geschlossenen Bereich verfügbaren Leistungen des Portals „pflegenzuhause.de“ nicht mehr in Anspruch nehmen.</p>
        <p><strong>4.&nbsp;&nbsp; &nbsp;Vertragsschluss, Vertragsbeendigung, persönliche Nutzung</strong></p>
        <p>4.1&nbsp;&nbsp; &nbsp;Vertragsschluss, Vertragsbeendigung</p>
        <p>4.1.1&nbsp;&nbsp; &nbsp;Auf dem Portal „pflegenzuhause.de“ kann ein Vertrag über den E-Learning-Kurs „Pflegen zu Hause“ (inklusive Lernbox in Printfassung oder eBook intergriert im Kurs) abgeschlossen werden. werden. Die Darstellungen der Informationen bzw. des E-Learning-Kurses in dem Portal stellen noch kein verbindliches Angebot zum Abschluss eines Vertrags, sondern eine Aufforderung zur Abgabe eines Angebotes durch den Nutzer dar. Das verbindliche Angebot zum Abschluss eines Vertrages gibt der Nutzer dadurch ab, dass er das Produkt auswählt und – gegebenenfalls nach Eingabe seiner Login- oder Bestell-Daten – die entsprechende Schaltfläche zur Bestellung „Kostenpflichtig bestellen“ oder Freischaltung des Dienstes anklickt. Bis zu diesem Zeitpunkt kann der Nutzer in dem Bestellprozess zurückspringen und ggf. seine Eingaben korrigieren oder den Bestellvorgang durch Schließen des Browserfensters abbrechen. Springer bestätigt den Eingang der Bestellung unverzüglich per E-Mail. Die Zugangsbestätigung per E-Mail oder die Anzeige der Meldung auf der Website ist noch keine Annahme der Bestellung.</p>
        <p>4.1.2&nbsp;&nbsp; &nbsp;Der Vertrag zwischen Springer und dem Nutzer kommt zustande, wenn Springer die Annahme dadurch erklärt, dass Springer (i) die entsprechenden Inhalte für den Nutzer freischaltet oder (ii) ihm die Annahme per E-Mail bestätigt. Springer behält es sich vor, das Angebot des Nutzers ohne Angabe von Gründen abzulehnen.</p>
        <p>4.1.3&nbsp;&nbsp; &nbsp;Verträge über den E-Learning-Kurs haben die auf dem Portal angegebene Laufzeit. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt für beide Parteien unberührt.</p>
        <p>4.1.4&nbsp;&nbsp; &nbsp;Jede Kündigung bedarf der Textform. Bitte richten Sie eine etwaige Kündigung beispielsweise per Post an Springer Medizin Verlag GmbH, z.Hd. Springer Customer Service Center, Tiergartenstr. 15-17, 69121 Heidelberg, per Fax an +49 6221 345 4229 oder per Email an:&nbsp;leserservice@springer.com.</p>
        <p>4.1.5&nbsp;&nbsp; &nbsp;Getätigte Bestellungen werden bei Springer nach Vertragsabschluss gespeichert. Hierzu ist es notwendig, dass personenbezogene Daten zur Abwicklung des Vertrages bei Springer hinterlegt werden. Weitere Informationen zum Datenschutz finden Sie unter Ziffer 14.</p>
        <p>4.2&nbsp;&nbsp; &nbsp;Persönliche Nutzung</p>
        <p>Ebenso, wie die Zugangsdaten ausschließlich für den Nutzer persönlich zur Verfügung gestellt werden (vgl. Ziffer 3.2.2), sind Inhalte bzw. Produkte, die im geschlossenen Login-Bereich des Portals „pflegenzuhause.de“ bezogen werden können, ausschließlich für den Nutzer persönlich bestimmt. Der Zugang zu derartigen Inhalten und Bereichen des Dienstes von „pflegenzuhause.de“, darf Dritten nicht zur Verfügung gestellt werden.</p>
        <p><strong>5.&nbsp;&nbsp; &nbsp;E-Learning-Kurs inklusive Print-Inhalte</strong></p>
        <p>5.1&nbsp;&nbsp; &nbsp;Leistungen von Springer</p>
        <p>Der Nutzer hat die Möglichkeit, im Portal „pflegenzuhause.de“ Zugang zu einem kostenpflichtigen Paket für den E-Learning-Kurs „Pflegen zu Hause“ zu bestellen. Zusätzlich zum Zugang zu dem E-Learning-Kurs werden an den Nutzer zum Kurs gehörende Print-Inhalte versendet oder als eBook mit dem Kurs freigeschaltet . Die Einzelheiten hinsichtlich des Produktes, der Preise und der sonstigen Vertragsbedingungen sind den Angebotsdarstellungen auf dem Portal zu entnehmen.</p>
        <p>5.2&nbsp;&nbsp; &nbsp;E-Learning-Kurs</p>
        <p>Der E-Learning-Kurs umfasst den Zugang auf alle Module des Kurses für den vereinbarten Zeitraum. Der E-Learning-Kurs umfasst die am Tag der Bestellung in dem E-Learning-Kurs enthaltenen Module. Wird der E-Learning-Kurs inhaltlich überarbeitet, so erhält der Nutzer Zugang zu dem inhaltlich überarbeiteten Kurs. Der Kurs kann während der Vertragslaufzeit in der Form, wie er ggf. aktualisiert von Springer bereitgestellt wird, beliebig wiederholt werden. Die Auswahl und die Anzahl der in einem Kurs enthaltenen Module werden von Springer festgelegt und können sich ändern. Bei der inhaltlichen Gestaltung, Abänderung und/oder Anpassung von Kursmodulen ist Springer frei, solange der Gesamtcharakter des E-Learning-Kurses erhalten bleibt. Die Einzelheiten der Leistungen des E-Learning-Kurses, insbesondere dessen Preis und die im Kurs im Zeitpunkt der Bestellung enthaltenen Module sind auf den entsprechenden Seiten im Portal beschrieben. Der Vertrag endet mit Ablauf des vereinbarten Zeitraums. Eine automatische Verlängerung des Vertrages findet nicht statt.</p>
        <p>Aufgrund von erforderlichen Aktualisierungen des E-Learning-Kurses kann es vorkommen, dass der Zugang zu dem Kurs kurzzeitig nicht zur Verfügung steht.</p>
        <p>5.3&nbsp;&nbsp; &nbsp;Hinsichtlich des Vertragsschlusses gilt Ziffer 4.1 dieser Allgemeinen Nutzungs- und Geschäftsbedingungen entsprechend.</p>
        <p><strong>7.&nbsp;&nbsp; &nbsp;Widerrufsrecht für Verbraucher im Sinne von § 13 Bürgerliches Gesetzbuch</strong></p>
        <p>Ist der Nutzer Verbraucher im Sinne des § 13 des Bürgerlichen Gesetzbuches, das heißt, eine natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können, besteht ein gesetzliches Widerrufsrecht, über das Springer wie folgt belehrt:</p>

        <p><strong>Widerrufsbelehrung</strong></p>
        <p><strong>Widerrufsrecht</strong></p>
        <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
        <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die erste Ware in Besitz genommen haben bzw. hat.</p>
        <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Springer Medizin Verlag GmbH, z.Hd. Springer Customer Service Center, Tiergartenstr. 15-17, 69121 Heidelberg, Fax: +49 6221 345 4229 oder per Email an: leserservice@springer.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das nachstehende Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</p>
        <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
        <p>Das Widerrufsrecht besteht nicht bei der Lieferung von Zeitungen, Zeitschriften und Illustrierten mit Ausnahme von Abonnement-Verträgen.</p>
        <p>Folgen des Widerrufs</p>
        <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.</p>
        <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
        <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
        <p><strong>Besonderer Hinweis</strong></p>
        <p>Haben Sie ein Paket aus Waren und/oder digitalen Inhalten bestellt, bei dem die Leistungen wirtschaftlich so miteinander verbunden sind, dass ein Bestandteil nicht oder nur zu anderen Konditionen ohne den anderen Teil bestellt werden kann (z. B. Kauf eines Buches zusammen mit einem digitalem Abonnement), und steht Ihnen aufgrund eines mit dem Vertrag über den digitalen Inhalt zusammenhängenden Vertrags ein Widerrufsrecht zu, so erstreckt sich dieses Widerrufsrecht auch auf den Vertrag über den digitalen Inhalt.</p>
        <p><strong>Muster-Widerrufsformular</strong></p>
        <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
        <p>An Springer-Verlag GmbH, z.Hd. Springer Customer Service Center, Tiergartenstr. 15-17, 69121 Heidelberg, Fax: +49 6221  345  4229 oder per Email an: leserservice@springer.com</p>
        <p>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der</p>
        <p>Folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)<br />Bestellt am (*)/erhalten am (*)<br />Name des/der Verbraucher(s)<br />Anschrift des/der Verbraucher(s)<br />Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br />Datum<br />___<br />(*) Unzutreffendes streichen.</p>

        <p><strong>8.&nbsp;&nbsp; &nbsp;Eigentumsvorbehalt</strong></p>
        <p>Die gelieferte Ware bleibt Eigentum der Springer Medizin Verlag GmbH bis zur vollständigen Bezahlung aller bestehenden Haupt- und Nebenforderungen aus dem geschlossenen Vertrag.</p>
        <p><strong>9.&nbsp;&nbsp; &nbsp;Preise und Zahlungsbedingungen</strong></p>
        <p>9.1&nbsp;&nbsp; &nbsp;Es gelten die zum Zeitpunkt der Bestellung auf dem Portal mitgeteilten Preise. Alle Preise verstehen sich inklusive der jeweils gesetzlich geltenden Umsatzsteuer sowie grundsätzlich zuzüglich etwaiger Versandkosten, es sei denn, Versandkosten sind als im Preis eingeschlossen angegeben.</p>
        <p>9.2&nbsp;&nbsp; &nbsp;Soweit nicht anders vereinbart, wird das Entgelt mit Bestellung der Ware bzw. Freischaltung der Dienste im Voraus zur Zahlung fällig. Die Abrechnung erfolgt entsprechend der vom Nutzer gewählten Zahlungsart. Springer ist aber nicht verpflichtet, dem Nutzer verschiedene Zahlungsweisen anzubieten.</p>
        <p>9.3&nbsp;&nbsp; &nbsp;Der Nutzer erhält eine Rechnung in elektronischer Form an die von ihm genannte E-Mail-Adresse. Wünscht er auch die Zusendung einer Papierrechnung, kann Springer hierfür ein gesondertes Entgelt berechnen.</p>
        <p>9.4&nbsp;&nbsp; &nbsp;Kommt der Nutzer seinen Zahlungsverpflichtungen nicht nach, ist Springer berechtigt, seine Leistungen zurückzubehalten, insbesondere den Zugang zu dem jeweiligen Dienst zu sperren. Nach Ausgleich der offen stehenden Forderungen wird die Leistung wieder aufgenommen bzw. der Zugang wieder geöffnet. Weitere und weitergehende Ansprüche, insbesondere solche auf Ersatz des Verzugsschadens und einer etwaigen außergerichtlichen Rechtsverfolgung, behält sich Springer ebenso vor wie die Erklärung der außerordentlichen Kündigung bei Vorliegen der gesetzlichen Voraussetzungen.</p>
        <p><strong>10.&nbsp;&nbsp; &nbsp;Rechte an Inhalten</strong></p>
        <p>10.1&nbsp;&nbsp; &nbsp;Die Inhalte des Portals „pflegenzuhause.de“, also Kursunterlagen, Fotos, Videos etc. (nachfolgend als „Inhalte“ bezeichnet) sind urheber- oder leistungsschutzrechtlich geschützt.</p>
        <p>10.2&nbsp;&nbsp; &nbsp; Der Nutzer ist nicht berechtigt, Inhalte jenseits der gesetzlichen Erlaubnistatbestände zu vervielfältigen, zu verbreiten, vorzuführen, öffentlich zugänglich zu machen oder zu senden und/oder Bearbeitungen der Inhalte zu veröffentlichen oder zu verwerten. Dies betrifft vor allem das Einräumen von Zugängen für Dritte auf die abgespeicherten Inhalte, das Einspeisen der Inhalte in ein Netzwerk (Internet, Intranet etc.) sowie die gewerbliche oder im Rahmen einer selbstständigen Tätigkeit erfolgende Informationsvermittlung gegenüber Dritten.</p>
        <p><strong>11.&nbsp;&nbsp; &nbsp;Datensicherung</strong></p>
        <p>Es obliegt dem Nutzer, für ihn relevante Daten und Informationen regelmäßig selbst gegen Verlust, Beschädigung und Veränderung auf einem der Bedeutung der Daten angemessenen technischen Niveau zu sichern.</p>
        <p><strong>12.&nbsp;&nbsp; &nbsp;Gewährleistung</strong></p>
        <p>12.1&nbsp;&nbsp; &nbsp; Hinsichtlich der kostenpflichtigen Leistungen gelten grundsätzlich die gesetzlichen Gewährleistungsbestimmungen, dies jedoch, sofern der Nutzer die Dienste des Portals „pflegenzuhause.de“ als Unternehmer i.S.v. § 14 BGB in Anspruch nimmt, mit den folgenden Besonderheiten:</p>
        <p>Die Gewährleistungszeit ist auf ein Jahr verkürzt, es sei denn, es geht um Schadensersatzansprüche in Folge der Verletzung von Leib, Leben und Gesundheit, um von Springer übernommene Garantien oder um auf Seiten von Springer vorliegenden Vorsatz oder arglistiges Verschweigen eines Mangels. Zudem hat der Nutzer die Leistung unverzüglich nach Lieferung/Erbringung zu untersuchen. Sofern sich dabei ein Mangel zeigt, muss der Nutzer diesen gegenüber Springer unverzüglich schriftlich rügen. Zeigt sich ein solcher Mangel erst später, muss der Nutzer den Mangel unverzüglich nach dessen Entdeckung rügen. Erfolgt die Rüge danach nicht rechtzeitig, können Gewährleistungsrechte nicht mehr geltend gemacht werden.</p>
        <p>12.2&nbsp;&nbsp; &nbsp;Kundendienst (Erreichbarkeit, Kontaktdaten)</p>
        <p>Unser Kundenservice steht Ihnen montags bis freitags von 9 bis 17 Uhr zur Verfügung.</p>
        <p>Tel.&nbsp;0800 77 80 777&nbsp;(kostenlos)<br />Tel.&nbsp;+49 (0) 30 827 87 55 66&nbsp;(kostenpflichtig)</p>
        <p>support@aok.pflegenzuhause.de</p>
        <p><strong>13.&nbsp;&nbsp; &nbsp;Haftung</strong></p>
        <p>13.1&nbsp;&nbsp; &nbsp; Resultieren Schäden des Nutzers aus dem Verlust von Daten, so haftet Springer hierfür unabhängig von einer etwaigen Beteiligung nicht, soweit die Schäden durch eine zweckgemäße, regelmäßige und vollständige Sicherung aller relevanten Daten durch den Nutzer (siehe Ziffer 11.) vermieden worden wären.</p>
        <p>13.2&nbsp;&nbsp; &nbsp;Allgemeine Haftungsregelungen</p>
        <p>13.2.1&nbsp;&nbsp; &nbsp;Ansonsten haftet Springer für Schäden des Nutzers, die vorsätzlich oder grob fahrlässig verursacht wurden, die Folge des Nichtvorhandenseins einer garantierten Beschaffenheit des Leistungsgegenstandes sind, die auf einer schuldhaften Verletzung wesentlicher Vertragspflichten (so genannte Kardinalpflichten) beruhen, die Folge einer schuldhaften Verletzung der Gesundheit, des Körpers oder des Lebens sind oder für die eine Haftung nach dem Produkthaftungsgesetz vorgesehen ist, nach den gesetzlichen Bestimmungen.</p>
        <p>13.2.2&nbsp;&nbsp; &nbsp;Kardinalpflichten sind solche vertraglichen Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, und deren Verletzung auf der anderen Seite die Erreichung des Vertragszwecks gefährdet.</p>
        <p>13.2.3&nbsp;&nbsp; &nbsp;Bei Verletzung einer Kardinalpflicht ist die Haftung – soweit der Schaden lediglich auf leichter Fahrlässigkeit beruht und nicht Leib, Leben oder Gesundheit betrifft – beschränkt auf solche Schäden, mit deren Entstehung im Rahmen der Erbringung von Leistungen wie der vertragsgegenständlichen Leistungen typischerweise und vorhersehbarerweise gerechnet werden muss.</p>
        <p>13.2.4&nbsp;&nbsp; &nbsp;Im Übrigen ist die Haftung – gleich aus welchem Rechtsgrund – sowohl Springers als auch seiner Erfüllungs- und Verrichtungsgehilfen ausgeschlossen.</p>
        <p><strong>14.&nbsp;&nbsp; &nbsp;Datenschutz</strong></p>
        <p>Springer behandelt die Daten des Nutzers vertraulich und achtet die einschlägigen Datenschutzbestimmungen. Alle Informationen zu Thema Datenschutz finden sich in der Datenschutzerklärung.</p>
        <p><strong>15.&nbsp;&nbsp; &nbsp;Änderungen der Allgemeinen Nutzungs- und Geschäftsbedingungen</strong></p>
        <p>15.1&nbsp;&nbsp; &nbsp;Springer behält es sich vor, die Allgemeinen Nutzungs- und Geschäftsbedingungen jederzeit und ohne Angabe von Gründen zu ändern. Die neuen Allgemeinen Nutzungs- und Geschäftsbedingungen werden dem Nutzer per E-Mail übermittelt. Die neuen Allgemeinen Nutzungs- und Geschäftsbedingungen gelten als vereinbart, wenn der Nutzer ihrer Geltung nicht innerhalb von 4 Wochen nach Zugang der E-Mail widerspricht. Der Widerspruch bedarf der Textform. Springer wird den Nutzer in der E-Mail auf die Widerspruchsmöglichkeit, die Frist und die Folgen der Untätigkeit des Nutzers gesondert hinweisen. Widerspricht der Nutzer, hat jede Partei das Recht, die betreffende Vereinbarung durch Kündigung mit sofortiger Wirkung zu beenden.</p>
        <p>15.2&nbsp;&nbsp; &nbsp;Die Möglichkeit der Änderung der Allgemeinen Nutzungs- und Geschäftsbedingungen besteht aber weder für Änderungen, die Inhalt und Umfang der für den jeweiligen Nutzer bestehenden Kernnutzungsmöglichkeiten des Dienstes „pflegenzuhause.de“ zum Nachteil des Nutzers einschränken, noch für die Einführung von neuen, bisher nicht in den Allgemeinen Nutzungs- und Geschäftsbedingungen angelegten Verpflichtungen für den Nutzer.&nbsp;</p>
        <p><strong>16.&nbsp;&nbsp; &nbsp;Beschwerden</strong></p>
        <p>Die EU-Kommission hat eine Onlineplattform für die außergerichtliche Streitschlichtung zwischen Verbrauchern und Unternehmern eingerichtet. Dadurch können Verbraucher Streitigkeiten im Zusammenhang mit Onlinebestellungen zunächst ohne die Einschaltung eines Gerichts klären. Die Plattform ist unter folgendem externen Link erreichbar: http://ec.europa.eu/consumers/odr/.</p>
        <p><strong>17.&nbsp;&nbsp; &nbsp;Schlussbestimmungen</strong></p>
        <p>17.1&nbsp;&nbsp; &nbsp;Auf die Vertragsverhältnisse mit dem Nutzer sowie auf diese Allgemeinen Nutzungs- und Geschäftsbedingungen und ihre Auslegung findet ausschließlich das Recht der Bundesrepublik Deutschland Anwendung. Die Anwendung des deutschen Internationalen Privatrechts sowie des UN-Kaufrechts ist ausgeschlossen. Ist der Nutzer ein Verbraucher, sind darüber hinaus die zwingenden Verbraucherschutzbestimmungen anwendbar, die in dem Staat gelten, in denen der Nutzer seinen gewöhnlichen Aufenthalt hat, sofern diese dem Nutzer einen weitergehenden Schutz bieten.</p>
        <p>17.2&nbsp;&nbsp; &nbsp;Ist der Nutzer Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen oder hat der Nutzer keinen ständigen Wohnsitz im Inland, so ist der Gerichtsstand für Rechtsstreitigkeiten aus oder im Zusammenhang mit diesem Vertrag Berlin. Ist der Nutzer Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen oder hat der Nutzer keinen ständigen Wohnsitz im Inland, so ist auch der Erfüllungsort für alle sich aus den Vertragsverhältnissen mit dem Nutzer ergebenden Rechte und Pflichten Berlin.</p>
        <p>17.3&nbsp;&nbsp; &nbsp;Sind oder werden einzelne Bestimmungen dieser Allgemeinen Nutzungs- und Geschäftsbedingungen unwirksam und/oder undurchsetzbar, so bleibt die Gültigkeit der Allgemeinen Nutzungs- und Geschäftsbedingungen im Übrigen unberührt. Unwirksame und/oder undurchsetzbare Bestimmungen werden im Wege der ergänzenden Vertragsauslegung durch eine wirksame und durchsetzbare Bestimmung ersetzt, die unter Berücksichtigung der Interessenlage beider Parteien dem gewünschten wirtschaftlichen Zweck am ehesten zu erreichen geeignet sind. Entsprechendes gilt für die Ausfüllung von Lücken in diesen Allgemeinen Nutzungs- und Geschäftsbedingungen. Die Sätze 2 und 3 dieser Ziffer 17.3 gelten nicht, wenn der Nutzer Verbraucher im Sinne von § 13 BGB ist.</p>
        <p>17.4&nbsp;&nbsp; &nbsp;Springer richtet sich mit seinen Produkten vornehmlich an deutschsprachige Nutzer. Die Vertragsbedingungen und die Verbraucherinformationen stehen daher ausschließlich in deutscher Sprache zur Verfügung. Die Springer Medizin Verlag GmbH sieht keine gesonderten Vertragstexte vor. Der Inhalt der mit Springer geschlossenen Vereinbarungen folgt daher aus diesen Allgemeinen Nutzungs- und Geschäftsbedingungen zusammen mit der Identität Springers und dem Nutzer und den Gegenstand der geschlossenen Verträge. Springer speichert insofern nicht „den Vertragstext“ speziell zur Person des Nutzers.</p>
        <p>Stand: November 2022</p>
    </div>
    </article>)
