import React, {useState} from 'react';
import {Formik, Field} from 'formik';
import './registrationForm.css';
import {Helmet} from "react-helmet";
export const  RegistrationForm = ({isSubmitted, setIsSubmitted}) => {
    const [isLoading, setIsLoading] = useState(false);

    const validateFirstname = value => {
        let error;
        if(!value) {
            error = 'Bitte geben Sie Ihren Vornamen an.';
        }
        return error
    };
    const validateLastname = value => {
        let error;
        if(!value) {
            error = 'Bitte geben Sie Ihren Nachnamen an.';
        }
        return error
    };
    const validateEmail = value => {
        let error;
        if (!value) {
            error = 'Bitte geben Sie Ihre E-Mail-Adresse an, z.B. erika.mustermann@online.de';
        } else if (!/^(?!(?:.+?[.]{2}.+?))[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Bitte geben Sie Ihre E-Mail-Adresse an, z.B. erika.mustermann@online.de';
        }
        return error;
    };
    const validatePrivacy = value => {
        let error;
        if(!value) {
            error = "Bitte bestätigen Sie die Datenschutzerklärung";
        }
        return error;
    };
    return (
        <div className="formcontainer">
            <Helmet>
                <meta charSet="utf-8" />
                <title>AOK Registrierung</title>
            </Helmet>
            <div className="contentstick" id="registrieren">
                <section id="c6" >
                    <div className="container">
                        <div className="login-box">
                            {isSubmitted &&
                                <div className="registerSuccess">
                                    <h1>Vielen Dank.</h1>
                                    <p>Wir haben nun eine Mail an die von Ihnen angegebene Adresse gesendet. Bitte rufen Sie diese Mail auf und klicken auf die darin enthaltene Verknüpfung. Sie werden anschließend zum Lehrgang weitergeleitet.</p>
                                    <p>Wenn Sie keine Email von uns bekommen, sehen Sie bitte im Spam-Ordner Ihres Mail-Programms nach.</p>
                                    <p>Bei technischen Problemen schreiben Sie bitte an <br/>support@aok.pflegenzuhause.de.</p>
                                </div>
                            }
                            {!isSubmitted && <div className="onlineCourse">Onlinekurs: PFLEGEN ZU HAUSE</div>}
                            { !isSubmitted && <h1 className="login-title">Jetzt registrieren</h1>}
                            {!isSubmitted && <p className="infoText">Damit Sie am Onlinekurs PFLEGEN ZU HAUSE teilnehmen können, benötigen wir die folgenden Informationen von Ihnen.</p>}
                            {!isSubmitted && <p className="infoText">Sie haben sich bereits registriert? Dann melden Sie sich direkt an: <a href="https://springer-aok.lms-plattform.de/login/index.php">Anmeldung</a></p>}
                            { !isSubmitted && <Formik
                                validateOnBlur
                                    initialValues={{firstname: '', lastname: '', email: '', email2: '', privacy: false}}
                                    onSubmit={(values) => {
                                        console.log(values);
                                        if(!/^(?!(?:.+?[.]{2}.+?))[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                            alert('Bitte geben Sie Ihre E-Mail-Adresse an, z.B. erika.mustermann@online.de');
                                            return;
                                        }
                                        const formData = new FormData();
                                        Object.keys(values).forEach(key => formData.append(key, values[key]));
                                        setIsLoading(true);
                                        fetch('/createuser.php',{
                                            method: "POST",
                                            body: formData,
                                        }).then(() => {
                                            window.scrollTo(0, 0);
                                            setIsLoading(false);
                                            setIsSubmitted(true);
                                        }).catch(err => setIsLoading(false));
                                    }}
                                    render={p => (
                                        <div className="login-form">
                                        <form
                                            onChange={() => {

                                            }}
                                            onSubmit={p.handleSubmit}
                                        >
                                            <label htmlFor="firstName">Vorname</label>
                                            <div className="input-group">
                                                <span className="icon icon-user"></span>
                                                <Field validate={validateFirstname}  className="form-control custinput" onChange={p.handleChange} onBlur={p.handleBlur} name="firstname" placeholder="Vorname" value={p.values.firstname} />
                                                {p.errors.firstname && p.touched.firstname && <div className="error-message">{p.errors.firstname}</div>}
                                            </div>
                                            <label htmlFor="lastName">Nachname</label>
                                            <div className="input-group">
                                                <span className="icon icon-user"></span>
                                                <Field validate={validateLastname}  className="form-control custinput" onChange={p.handleChange} onBlur={p.handleBlur} name="lastname" placeholder="Nachname" value={p.values.lastname} />
                                                {p.errors.lastname && p.touched.lastname && <div className="error-message">{p.errors.lastname}</div>}
                                            </div>
                                            <label htmlFor="email">E-Mail</label>
                                            <div className="input-group">
                                                <span className="icon icon-envelop"></span>
                                                <Field validate={validateEmail}  className="form-control custinput" onChange={p.handleChange} onBlur={p.handleBlur} name="email" placeholder="E-Mail" value={p.values.email} />
                                                {p.errors.email && p.touched.email && <div className="error-message-email">{p.errors.email}</div>}
                                            </div>
                                            <label htmlFor="email" className="emailCheck">E-Mail wiederholen</label>
                                            <div className="input-group">
                                                <span className="icon icon-envelop"></span>
                                                <Field validate={validateEmail}  className="form-control custinput" onChange={p.handleChange} onBlur={p.handleBlur} name="email2" placeholder="E-Mail-wiederholen" value={p.values.email2} />
                                                    {(p.values.email !== p.values.email2) && p.touched.email2 && <div className="error-message-email">{"Die Angaben zur E-Mail stimmen nicht überein."}</div>}
                                            </div>
                                            <div className="privacy">
                                                <Field validate={validatePrivacy} type="checkbox" value="privacy" name="privacy" checked={p.values.privacy} onChange={p.handleChange} id="privacy"/>
                                                <label className="privacy-text" style={{marginLeft: 5, marginTop: 10}} htmlFor="privacy">Ich habe die <a href="/datenschutz">Datenschutzerklärung</a> gelesen.</label>
                                                {p.errors.privacy && p.touched.privacy && <div className="error-message-privacy">{p.errors.privacy}</div>}
                                            </div>
                                            <div className="support">
                                                <div>
                                                    Bei technischen Problemen schreiben Sie bitte an<br/>
                                                    support@aok.pflegenzuhause.de.
                                                </div>
                                            </div>
                                            <div className="custformalign">
                                                <div className="width50 text-right">
                                                        <button disabled={isLoading || !p.values.email.length || p.values.email !== p.values.email2} className="btn btn-success btn-lg btn-block custbutt custbuttvip" type="submit">Jetzt registrieren</button>
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                    )}
                                /> }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
};
