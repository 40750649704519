import React from 'react';
import {slide as Burger} from 'react-burger-menu';
import './mobilemenu.scss';
import {Link} from "react-router-dom";
import CloseButton from "./img/button-schlieen.svg";
export class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    closeMenu = (e) => {
        this.setState({
            isOpen: false
        })
    };

    render() {
       return (
           <Burger width={429} right customCrossIcon={false} isOpen={this.state.isOpen} >
               <div className="sidenav" id="mySidenav1">
                   <img onClick={() => this.closeMenu()} className="close-button" alt="closeButton" src={CloseButton}/>
                   <div className="items">
                       <span className="slide_border" id="no_hover" href="#"><b>AOK Pflegen zu Hause</b></span>
                       <Link to="/" className="slide_border" onClick={() => {this.props.setIsSubmitted(false);   window.scrollTo(0, 0)
                       }}>Registrieren</Link>
                       <a className="slide_border" href="#top">FAQ*LINK</a>
                       <a className="slide_border" href="#top">Kontakt*LINK</a>
                   </div>
               </div>
           </Burger>
       )
   }
}

